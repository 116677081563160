import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/css/Security.css";
import HrLines from "../designs/HrLines";
import { securityboxes } from "../../Data/boxesData.js";
import { Helmet } from "react-helmet";
import reverse from "../../assets/icons/reverse.png";
import soc from "../../assets/logos/soc.png";
import { motion } from "framer-motion";

const flipBox = (id) => {
  const clickedBox = document.getElementById(`box-${id}`);
  const allBoxes = document.querySelectorAll(".box");

  // Remove 'flipped' class from all boxes except the clicked one
  allBoxes.forEach((box) => {
    if (box !== clickedBox) {
      box.classList.remove("flipped");
    }
  });

  // Toggle 'flipped' class for the clicked box
  clickedBox.classList.toggle("flipped");
};

const Security = () => {
  return (
    <div>
      <Helmet>
        <title>Secured and Compliant Lending Solutions - Cync Software®</title>
        <meta
          name="description"
          content="Cync Software runs on AWS, ensuring secure, resilient infrastructure with robust monitoring and compliance for clients."
        />
      </Helmet>

      <motion.div
        className="Productright"
        initial={{ opacity: 0, x: 50 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
        viewport={{ once: true, amount: 0.3 }}
      >
        <section className="security-sections">
          <Container className="h-100">
            <Row className="h-100 align-items-center">
              <Col lg={9}>
                <div className="content">
                  <motion.div
                    className="Productleft"
                    initial={{ opacity: 0, x: -50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.8, ease: "easeOut" }}
                    viewport={{ once: true, amount: 0.7 }}
                  >
                    <h1>Security</h1>
                  </motion.div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </motion.div>
      <div className="space"></div>

      <section>
        <Container>
          <h1 className="privacyheading">Organizational Security Framework</h1>
          <HrLines />
          <br />
          <Row>
            <Col md={11}>
              <p className="securitylist">
                Cync Software proactively leads compliance through regular
                audits and assessments to ensure its software and facilities are
                secure and comply with international standards. Cync Software’s{" "}
                <b>SOC 2 certification</b> is a testament to the strict data
                security and privacy standards employed to protect the sensitive
                information of financial institutions using our software
                products and services. In addition, Cync Software employs all
                the necessary controls and processes to ensure its products and
                services comply with <b>ISO 27001:2022, NIST SP 800-53,</b> and{" "}
                <b>PCI DSS</b> standards and requirements. Hosted on Amazon Web
                Services (AWS), Cync Software’s products and services are built
                using the AWS Well-Architected Framework and recommendations
                from the Center for Internet Security (CIS). This comprehensive
                approach ensures exceptional security, scalability and
                resilience for our clients.
              </p>
            </Col>
            <Col
              md={1}
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <a href="http://www.aicpa.org/soc4so" target="_blank">
                <img src={soc} />
              </a>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="space"></div>

      <section>
        <Container>
          <h1 className="privacyheading">
            Comprehensive Security Management Practices
          </h1>
          <HrLines />
          <div className="space"></div>
          <Row className="box-row">
            {securityboxes.map((box) => (
              <Col key={box.id} md={3} sm={6} xs={12} className="box-col">
                <Card
                  className="box"
                  id={`box-${box.id}`}
                  onClick={() => flipBox(box.id)}
                >
                  <div className="front">
                    <div className="icon-top-right">
                      <img src={reverse} alt="Extra Icon" />
                    </div>
                    <img src={box.icon} alt={box.title} />
                    <br />
                    <h3>{box.title}</h3>
                  </div>
                  <div className="back">
                    <p>{box.description}</p>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <div className="space"></div>

      <section>
        <Container>
          <h1 className="privacyheading">
            Advanced Compliance Monitoring with AWS Security Hub
          </h1>
          <HrLines />
          <br />
          <p className="securitylist">
            Cync Software leverages <b>AWS Security Hub</b> for real-time
            compliance monitoring, ensuring continuous oversight and adherence
            to industry standards. Through AWS Security Hub, we monitor
            compliance against key frameworks including <b>CIS</b>,{" "}
            <b>NIST 800-53</b>, and
            <b> PCI DSS</b>. This proactive approach allows us to promptly
            identify and mitigate potential security risks, ensuring that our
            systems and services meet stringent regulatory requirements. By
            utilizing AWS Security Hub's comprehensive monitoring capabilities,
            we uphold a robust security posture, safeguarding data integrity and
            fostering trust with our clients and partners.
          </p>
        </Container>
      </section>
      <div className="space"></div>

      <section>
        <Container>
          <h1 className="privacyheading">
            Holistic Business Continuity Framework
          </h1>
          <HrLines />
          <br />
          <p className="securitylist">
            Cync Software employs a holistic business continuity framework to
            ensure resilience in the face of disruptions. Our comprehensive
            <b> Business Impact Analysis</b> (BIA) includes a directory of
            critical business functions and assessments of the potential impact
            of interruptions. Our BIA is designed to prioritize resources based
            on financial and operational considerations while executing recovery
            strategies. Our robust <b> Disaster Recovery Plan</b> (DRP), which
            borrows insights from the BIA, is focused on swiftly restoring IT
            systems and data. Our DRP outlines specific steps, resources, and
            timelines to ensure technical recovery, data integrity, and minimal
            downtime, enabling rapid resumption of critical services.
            Additionally, our
            <b> Business Continuity Plan</b> (BCP) ensures the continuation of
            essential business operations, encompassing personnel, facilities,
            communication, and systems. By integrating the triad of BIA, DRP,
            and BCP, we have a resilient and comprehensive approach to managing
            disruptions, safeguarding our operations, and maintaining client
            trust.
          </p>
          <br />
          ``{" "}
        </Container>
      </section>
      <div className="space"></div>
    </div>
  );
};

export default Security;
