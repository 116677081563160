import React, { useState, useEffect } from "react";
import "../../assets/css/CyncFactoring.css";
import { Container, Row, Col, Card, Carousel } from "react-bootstrap";
import Button from "../Button";
import HrLines from "../designs/HrLines";
import FactoringPopup from "../popup/LOS/FactoringPopup.jsx";
import VideoModal from "../VideoModal";
import testimonials from "../../Data/testimonialsData.js";
import { factoringboxes } from "../../Data/boxesData.js";
import reverse from "../../assets/icons/reverse.png";
import { Helmet } from "react-helmet";
import FactoringStepBoxes from "../StepBoxes/FactoringStepBoxes.jsx";
import { useNavigate } from "react-router-dom";
import factoringwebp from "../../assets/images/factoring.webp";
import factoringavif from "../../assets/images/factoring.avif";
import { motion } from "framer-motion";

const flipBox = (id) => {
  const clickedBox = document.getElementById(`box-${id}`);
  const allBoxes = document.querySelectorAll(".box");

  // Remove 'flipped' class from all boxes except the clicked one
  allBoxes.forEach((box) => {
    if (box !== clickedBox) {
      box.classList.remove("flipped");
    }
  });

  // Toggle 'flipped' class for the clicked box
  clickedBox.classList.toggle("flipped");
};

const CyncFactoring = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [lastActiveStep, setLastActiveStep] = useState(0);

  const handleStepClick = (index) => {
    if (activeStep === index) {
      setActiveStep(null);
    } else {
      setActiveStep(index);
      setLastActiveStep(index);
    }
  };

  const [showPopup, setShowPopup] = useState(false);

  const navigate = useNavigate();

  const handleShow = () => {
    // Leadfeeder tracking
    window.history.pushState({}, "", "/cync-factoring/form");
    // Push event to Google Analytics / Leadfeeder (if using Google Tag Manager)
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "popup_opened",
    });

    // If using Google Analytics (GA4)
    if (window.gtag) {
      window.gtag("event", "popup_opened", {
        event_category: "Popup",
        event_label: "Popup Form Opened",
      });
    }

    setShowPopup(true);
  };
 const handlemodelClose =() => {
    setOpen(false);
    setVideoUrl("");
    setCarouselPlaying(true);
  };

  const handleClose = () => {
    navigate(-1);
    setShowPopup(false);
    setOpen(false);
    setVideoUrl("");
    setCarouselPlaying(true);
  };

  const [open, setOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [carouselPlaying, setCarouselPlaying] = useState(true);

  const handleOpen = (url) => {
    setVideoUrl(url);
    setOpen(true);
    setCarouselPlaying(false);
  };

  const imageContainerStyle = {
    minHeight: "", // Set a minimum height for the image container
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "20px", // Adjust as needed
    overflow: "hidden", // Hide overflow to prevent abrupt resizing
    transition: "height 0.3s ease-in-out", // Smooth transition for height changes
    height: activeStep !== null ? "420px" : "420px", // Dynamic height based on activeStep
  };

  return (
    <div>
      <Helmet>
        <title>
          Invoice Factoring Software | Factoring Solution - Cync Software®
        </title>
        <meta
          name="description"
          content="Automate invoice validation and fast-track funding with a scalable, resilient cloud-based factoring platform. Schedule a demo!"
        />
      </Helmet>

      <section className="factoringtop-sections">
        <Container className="h-100">
          <Row className="h-100 align-items-center">
            <Col lg={7}>
              <div className="content">
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.3 }} // Trigger animation when 30% of the content is visible
                  variants={{
                    hidden: { opacity: 0 }, // Initial state for all children
                    visible: {
                      opacity: 1,
                      transition: {
                        staggerChildren: 0.5, // Delay between each child animation
                      },
                    },
                  }}
                >
                  <motion.div
                    variants={{
                      hidden: { opacity: 0, y: -20 }, // Fade and slide up
                      visible: { opacity: 1, y: 0 }, // Fade in and reset position
                    }}
                    transition={{ duration: 1 }}
                  >
                    <h4
                      style={{
                        color: "#E93740",
                        fontSize: "50px",
                        fontWeight: "700",
                      }}
                    >
                      Cync Factoring
                    </h4>
                    <h1 className="lostopheading">
                      Cloud-Based Factoring Platform to Automate Invoice
                      Validation and Fast-Track Funding to Sellers
                    </h1>
                  </motion.div>
                  <motion.div
                    variants={{
                      hidden: { opacity: 0, y: -20 }, // Fade and slide down
                      visible: { opacity: 1, y: 0 }, // Fade in and reset position
                    }}
                    transition={{ duration: 1 }}
                  >
                    <p className="toppara">
                      Streamline your end-to-end invoice validation and
                      verification process with automation. Switch to a highly
                      scalable and resilient cloud-based platform and elevate
                      both your back-office operations and seller experience.
                    </p>
                  </motion.div>
                  <motion.div
                    variants={{
                      hidden: { opacity: 0, y: -30 }, // Fade and slight zoom out
                      visible: { opacity: 1, y: 0 }, // Fade in and reset scale
                    }}
                    transition={{ duration: 1 }}
                  >
                    <Button size="small" color="primary" onClick={handleShow}>
                      Product Demo
                    </Button>
                  </motion.div>
                </motion.div>
              </div>
            </Col>
            <Col lg={5} className="topmob" style={{ position: "relative" }}>
              <motion.div
                initial={{ opacity: 0, y: 80 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, ease: "easeOut" }}
                viewport={{ once: true, amount: 0.3 }}
              >
                <picture>
                  <source
                    srcSet={factoringavif}
                    type="image/avif"
                    style={{
                      position: "absolute",
                      bottom: "-299px",
                      width: "auto",
                    }}
                  />
                  <source
                    srcSet={factoringwebp}
                    type="image/webp"
                    style={{
                      position: "absolute",
                      bottom: "-299px",
                      width: "auto",
                    }}
                  />
                  <img
                    src={factoringwebp}
                    alt="Bank"
                    style={{
                      position: "absolute",
                      bottom: "-299px",
                      width: "auto",
                    }}
                  />
                </picture>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="space"></div>

      <section>
        <Container className="h-100">
          <h1 className="privacyheading">
            Supercharge Your Lending With Cync Factoring
          </h1>
          <HrLines />
          <br />
          <p className="contentpara">
            Manually validating each invoice against multiple business rules to
            determine whether the invoice should be purchased is a
            time-consuming task. Similarly, contacting debtors via phone or
            email to verify invoice details slows down decision-making. These
            delays negatively impact the cash flow of sellers who rely on quick
            funding against their accounts receivable.
          </p>
          <p className="contentpara">
            Say goodbye to manual processing and embrace automation with Cync
            Factoring. Define custom rules to automatically validate invoices
            and send them to debtors electronically for rapid verification.
            Provide your collectors with a comprehensive dashboard to track and
            follow up effectively. Transform your back-office efficiency and
            impress your sellers with super-fast funding.
          </p>
        </Container>
      </section>
      <div className="space"></div>

      <section className="benefits-with-grids">
        <div className="benefitsgrid" style={{ width: "460px" }}>
          <div className="icon">
            <img
              src={require("../../assets/icons/improvement.png")}
              alt="Icon 2"
            />
          </div>
          <div className="text">
            <h6>4x</h6>
            <h5>Improvement in Speed to Fund</h5>
          </div>
        </div>
        <div className="benefitsgrid" style={{ width: "460px" }}>
          <div className="icon">
            <img
              src={require("../../assets/icons/threewhite.png")}
              alt="Icon 3"
            />
          </div>
          <div className="text">
            <h6>360⁰</h6>
            <h5>Visibility Into the Entire Invoice Lifecycle</h5>
          </div>
        </div>
      </section>
      <div className="space"></div>

      <section>
        <Container>
          <h1 className="privacyheading">Why Cync Factoring?</h1>
          <HrLines />
          <div className="space"></div>
          <FactoringStepBoxes />
        </Container>
      </section>

      <div className="space"></div>

      <section>
        <Container>
          <h1 className="privacyheading">Expansive Feature Set</h1>
          <HrLines />
          <div className="space"></div>
          <Row className="box-row">
            {factoringboxes.map((box) => (
              <Col key={box.id} md={3} sm={6} xs={12} className="box-col">
                <Card
                  className="box"
                  id={`box-${box.id}`}
                  onClick={() => flipBox(box.id)}
                >
                  <div className="front">
                    <div className="icon-top-right">
                      <img src={reverse} alt="Extra Icon" />
                    </div>
                    <img src={box.icon} alt={box.title} />
                    <br />
                    <h3>{box.title}</h3>
                  </div>
                  <div className="back">
                    <p>{box.description}</p>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <div className="space"></div>

      <section className="testimoni">
        <Carousel
          indicators={true}
          controls={false}
          interval={carouselPlaying ? 15000 : null}
          fade={true} // Add fade effect for smooth transition
          className="carousel-container"
        >
          {testimonials.map((testimonial, index) => (
            <Carousel.Item key={index} className="carousel-item">
              <img
                className="d-block w-100 carousel-image"
                src={testimonial.image}
                alt={`Slide ${index + 1}`}
              />
              <Carousel.Caption className="carousel-caption-custom">
                <h1 className="headingwhite">{testimonial.heading}</h1>
                <p className="testimobpara">{testimonial.text}</p>
                <p className="author-text">{testimonial.author}</p>
                <p className="none"></p>
                <p
                  style={{ fontSize: "11.5px" }}
                  className="testimobpara dispara"
                >
                  <i>{testimonial.disclaimer}</i>
                </p>
                <div
                  className="disclaimer"
                  style={{ gap: "10px", display: "flex" }}
                >
                  <Button
                    size="sm"
                    variant="light"
                    onClick={() => handleOpen(testimonial.videoUrl)}
                  >
                    Watch Video
                  </Button>
                  {(index === 0 || index === 1 || index === 2) && (
                    <a href={testimonial.pageUrl}>
                      <Button size="sm" variant="light">
                        Read More
                      </Button>
                    </a>
                  )}
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
        <VideoModal open={open} handlemodelClose={handlemodelClose} videoUrl={videoUrl} />
      </section>

      <div className="space"></div>

      <section className="demo-section">
        <div className="demo-content">
          <h1>Supercharge Your Factoring Now!</h1>
          <HrLines />
          <div className="space"></div>
          <Button size="small" color="primary" onClick={handleShow}>
            Product Demo
          </Button>
          <FactoringPopup show={showPopup} handleClose={handleClose} />
        </div>
      </section>
    </div>
  );
};

export default CyncFactoring;
