import React, { useState, useEffect } from "react";
import "./UmpBank.css";
import { Container, Row, Col, Card, Carousel } from "react-bootstrap";
import Button from "../../Button";
import HrLines from "../../designs/HrLines";
import VideoModal from "../../VideoModal";
import testimonial from "../../../Data/testimonialData.js";
import { Helmet } from "react-helmet";
import about from "../CustomerStories/about.png";
import challenge from "../CustomerStories/challenge.png";
import solution from "../CustomerStories/solution.png";
import business from "../CustomerStories/business.png";
import UmqPopup from "../../popup/LOS/UmqPopup.jsx";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import umqwebp from "../../../assets/images/bank.webp";
import umqavif from "../../../assets/images/bank.avif";
import { motion } from "framer-motion";

const Umpbank = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [lastActiveStep, setLastActiveStep] = useState(0);

  const location = useLocation();
  useEffect(() => {
    // Check if the URL contains ?popup=true
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get("popup") === "true") {
      setShowPopup(true); // Open popup automatically
    }
  }, [location]);

  const handleStepClick = (index) => {
    if (activeStep === index) {
      setActiveStep(null);
    } else {
      setActiveStep(index);
      setLastActiveStep(index);
    }
  };
  const [showPopup, setShowPopup] = useState(false);

  const navigate = useNavigate();

  const handleShow = () => {
    // Leadfeeder tracking
    window.history.pushState({}, "", "/customer-stories/umpqua-bank/form");
    // Push event to Google Analytics / Leadfeeder (if using Google Tag Manager)
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "popup_opened",
    });

    // If using Google Analytics (GA4)
    if (window.gtag) {
      window.gtag("event", "popup_opened", {
        event_category: "Popup",
        event_label: "Popup Form Opened",
      });
    }

    setShowPopup(true);
  };
 const handlemodelClose =() => {
    setOpen(false);
    setVideoUrl("");
    setCarouselPlaying(true);
  };

  const handleClose = () => {
    navigate(-1);
    setShowPopup(false);
    setOpen(false);
    setVideoUrl("");
    setCarouselPlaying(true);
  };

  const [open, setOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [carouselPlaying, setCarouselPlaying] = useState(true);

  const handleOpen = (url) => {
    setVideoUrl(url);
    setOpen(true);
    setCarouselPlaying(false);
  };

  return (
    <div>
      <Helmet>
        <title>Customer Stories - Cync Software®</title>
        <meta
          name="description"
          content="Automate collateral evaluation and borrowing base calculations with our industry-leading asset-based lending platform. Schedule a demo!"
        />
      </Helmet>

      <section className="Umpbank-sections">
        <Container className="h-100">
          <Row className="h-100 align-items-center">
            <Col lg={6}>
              <div className="content">
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.3 }} // Trigger animation when 30% of the content is visible
                  variants={{
                    hidden: { opacity: 0 }, // Initial state for all children
                    visible: {
                      opacity: 1,
                      transition: {
                        staggerChildren: 0.5, // Delay between each child animation
                      },
                    },
                  }}
                >
                  <motion.div
                    variants={{
                      hidden: { opacity: 0, y: -20 }, // Fade and slide up
                      visible: { opacity: 1, y: 0 }, // Fade in and reset position
                    }}
                    transition={{ duration: 1 }}
                  >
                    <h4
                      style={{
                        color: "#E93740",
                        fontSize: "50px",
                        fontWeight: "700",
                      }}
                    >
                      Customer Story
                    </h4>
                    <h1 className="lostopheading">
                      How Umpqua Bank Slashed Borrowing Base Processing Time by
                      92.5% With Cync ABL
                    </h1>
                  </motion.div>
                  <br />
                  <motion.div
                    variants={{
                      hidden: { opacity: 0, y: -20 }, // Fade and slide down
                      visible: { opacity: 1, y: 0 }, // Fade in and reset position
                    }}
                    transition={{ duration: 1 }}
                  >
                    <span className="benefitshead">
                      <div className="benefitsgrids" style={{ width: "160px" }}>
                        <div className="icon">
                          <picture style={{ width: "auto" }}>
                            <source
                              srcSet={require("../../../assets/icons/2x.avif")}
                              type="image/avif"
                            />
                            <source
                              srcSet={require("../../../assets/icons/2x.webp")}
                              type="image/webp"
                            />
                            <source
                              srcSet={require("../../../assets/icons/2x.jpg")}
                              type="image/png"
                            />
                            <img
                              style={{ width: "auto" }}
                              src={require("../../../assets/icons/2x.png")}
                              alt="Icon"
                            />
                          </picture>
                        </div>
                        <div className="text">
                          <h6 className="cshead">2X</h6>
                          <h5 className="csheads">Increase in productivity</h5>
                        </div>
                      </div>
                      <div className="benefitsgrids" style={{ width: "200px" }}>
                        <div className="icon">
                          <picture style={{ width: "auto" }}>
                            <source
                              srcSet={require("../../../assets/icons/3x.avif")}
                              type="image/avif"
                            />
                            <source
                              srcSet={require("../../../assets/icons/3x.webp")}
                              type="image/webp"
                            />
                            <source
                              srcSet={require("../../../assets/icons/3x.jpg")}
                              type="image/png"
                            />
                            <img
                              style={{ width: "auto" }}
                              src={require("../../../assets/icons/3x.png")}
                              alt="Icon"
                            />
                          </picture>
                        </div>
                        <div className="text">
                          <h6 className="cshead">3.3X</h6>
                          <h5 className="csheads">
                            Faster <br />
                            turnaround time
                          </h5>
                        </div>
                      </div>
                      <div className="benefitsgrids" style={{ width: "110px" }}>
                        <div className="icon">
                          <picture style={{ width: "auto" }}>
                            <source
                              srcSet={require("../../../assets/icons/80.avif")}
                              type="image/avif"
                            />
                            <source
                              srcSet={require("../../../assets/icons/80.webp")}
                              type="image/webp"
                            />
                            <source
                              srcSet={require("../../../assets/icons/80.jpg")}
                              type="image/png"
                            />
                            <img
                              style={{ width: "auto" }}
                              src={require("../../../assets/icons/80.png")}
                              alt="Icon"
                            />
                          </picture>
                        </div>
                        <div className="text">
                          <h6 className="cshead">60-80</h6>
                          <h5 className="csheads">Portfolios per analysts</h5>
                        </div>
                      </div>
                    </span>
                  </motion.div>
                  <br />
                  <div style={{ height: "12px" }}></div>
                  <motion.div
                    variants={{
                      hidden: { opacity: 0, y: -30 }, // Fade and slight zoom out
                      visible: { opacity: 1, y: 0 }, // Fade in and reset scale
                    }}
                    transition={{ duration: 1 }}
                  >
                    <Button size="small" color="primary" onClick={handleShow}>
                      Achieve Similar Results
                    </Button>
                  </motion.div>
                </motion.div>
              </div>
            </Col>
            <Col lg={6} className="topmob">
              <motion.div
                initial={{ opacity: 0, y: 80 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, ease: "easeOut" }}
                viewport={{ once: true, amount: 0.3 }}
              >
                <picture>
                  <source srcSet={umqavif} type="image/avif" />
                  <source srcSet={umqwebp} type="image/webp" />
                  <img src={umqwebp} alt="Bank" />
                </picture>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </section>

      <div className="space"></div>

      <section className="container">
        <div className="sections">
          <div className="sections-content">
            <h1>About Our Client</h1>
            <div
              style={{
                display: "flex",
                gap: "10px",
                marginTop: "7px",
                marginBottom: "10px",
              }}
            >
              <hr
                style={{
                  width: "75px",
                  borderColor: "#E93740",
                  borderWidth: "3px",
                  margin: "0",
                  borderRadius: "5px",
                  opacity: "inherit",
                }}
              />
              <hr
                style={{
                  width: "15px",
                  borderColor: "#E93740",
                  borderWidth: "3px",
                  margin: "0",
                  borderRadius: "5px",
                  opacity: "inherit",
                }}
              />
            </div>
          </div>
        </div>
        <div className="sections">
          <div className="sections-content">
            <p>
              Umpqua Bank, headquartered in Roseburg, Oregon, is a part of
              Umpqua Holdings Corporation and is renowned for its
              customer-centric approach to banking. With over $50 billion in
              assets, it has emerged as a top 30 publicly traded U.S. bank and a
              leading regional bank in the western U.S., operating across eight
              states. Umpqua Bank is dedicated to supporting its 5,000+
              associates, delivering exceptional service to its customers, and
              strengthening the communities it serves.
            </p>
          </div>
          <div className="sections-image">
            <picture style={{ width: "auto" }}>
              <source
                srcSet={about.replace(/\.\w+$/, ".avif")}
                type="image/avif"
              />
              <source
                srcSet={about.replace(/\.\w+$/, ".webp")}
                type="image/webp"
              />
              <source
                srcSet={about.replace(/\.\w+$/, ".jpg")}
                type="image/jpg"
              />
              <img src={about} alt="Placeholder" style={{ width: "auto" }} />
            </picture>
          </div>
        </div>
      </section>

      <div className="space"></div>

      <section className="bg">
        <section className="container">
          <div className="sections">
            <div className="sections-image"></div>
            <div className="sections-content">
              <h1>The Challenge</h1>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  marginTop: "7px",
                  marginBottom: "10px",
                }}
              >
                <hr
                  style={{
                    width: "75px",
                    borderColor: "#E93740",
                    borderWidth: "3px",
                    margin: "0",
                    borderRadius: "5px",
                    opacity: "inherit",
                  }}
                />
                <hr
                  style={{
                    width: "15px",
                    borderColor: "#E93740",
                    borderWidth: "3px",
                    margin: "0",
                    borderRadius: "5px",
                    opacity: "inherit",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="sections">
            <div className="sections-image">
              <picture style={{ width: "auto" }}>
                <source
                  srcSet={challenge.replace(/\.\w+$/, ".avif")}
                  type="image/avif"
                />
                <source
                  srcSet={challenge.replace(/\.\w+$/, ".webp")}
                  type="image/webp"
                />
                <source
                  srcSet={challenge.replace(/\.\w+$/, ".jpg")}
                  type="image/jpg"
                />
                <img
                  src={challenge}
                  alt="Placeholder"
                  style={{ width: "auto" }}
                />
              </picture>
            </div>
            <div className="sections-content">
              <p>
                Umpqua Bank faced significant challenges in managing a diverse
                portfolio of over 200 credits, ranging from $1 million to $35
                million across industries like lumber, fisheries, and aerospace.
                The bank relied on spreadsheets to process information from
                borrowing base certificates, accounts payables, inventory, and
                aging information for accounts receivables, to compute the
                borrowing base. This manual approach required extensive time and
                effort, with some tasks taking up to a full day to complete.
              </p>
              <p>
                With the bank’s expanding portfolio, the team needed a solution
                to:
              </p>
              <ul>
                <li>Manage increased workloads without additional staff</li>
                <li>
                  Address scalability limits, allowing analysts to exceed the
                  current capacity of 30-40 credits per month
                </li>
                <li>
                  Eliminate inefficiencies from manually processing hundreds of
                  pages of aging reports
                </li>
                <li>
                  Improve efficiencies, streamline workflows, and provide better
                  tracking
                </li>
              </ul>
            </div>
          </div>
        </section>
      </section>
      <div className="space"></div>

      <section className="container">
        <div className="sections">
          <div className="sections-contents">
            <h1>The Cync Solution</h1>
            <div
              style={{
                display: "flex",
                gap: "10px",
                marginTop: "7px",
                marginBottom: "10px",
              }}
            >
              <hr
                style={{
                  width: "75px",
                  borderColor: "#E93740",
                  borderWidth: "3px",
                  margin: "0",
                  borderRadius: "5px",
                  opacity: "inherit",
                }}
              />
              <hr
                style={{
                  width: "15px",
                  borderColor: "#E93740",
                  borderWidth: "3px",
                  margin: "0",
                  borderRadius: "5px",
                  opacity: "inherit",
                }}
              />
            </div>
          </div>
        </div>
        <div className="sections">
          <div className="sections-content">
            <p>
              Umpqua Bank chose Cync ABL to modernize its credit administration
              processes. With Cync ABL, Umpqua Bank accomplished:
            </p>
            <ul>
              <li>
                <b>Non-Linear Scalability</b>: Expanded operations without
                increasing headcount, efficiently overseeing a diverse portfolio
                across industries, each with distinct complexities
              </li>
              <li>
                <b>Faster Borrowing Base Calculations</b>: Analysts utilized
                automation to upload and process Borrowing Base Certificates,
                Accounts Receivables, Accounts Payables, and other critical
                data, significantly speeding up the borrowing base calculation
                process
              </li>
              <li>
                <b>Expedited Aging</b>: Automated workflows simplified the
                review and categorization of hundreds of pages of accounts
                receivables, enabling precise and accelerated aging calculations
              </li>
            </ul>
            <p>
              Cync provided dedicated support, personalized training sessions
              led by subject matter experts and product experts, continuous
              technical assistance, and tailored reporting solutions to address
              the bank’s specific needs.
            </p>
          </div>
          <div className="sections-image">
            <picture style={{ width: "auto" }}>
              <source
                srcSet={solution.replace(/\.\w+$/, ".avif")}
                type="image/avif"
              />
              <source
                srcSet={solution.replace(/\.\w+$/, ".webp")}
                type="image/webp"
              />
              <source
                srcSet={solution.replace(/\.\w+$/, ".jpg")}
                type="image/jpg"
              />
              <img src={solution} alt="Placeholder" style={{ width: "auto" }} />
            </picture>
          </div>
        </div>
        <br />
      </section>
      <div className="space"></div>

      <section className="bg">
        <section className="container">
          <div className="sections">
            <div className="sections-image"></div>
            <div className="sections-content">
              <h1>Business Impact</h1>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  marginTop: "7px",
                  marginBottom: "10px",
                }}
              >
                <hr
                  style={{
                    width: "75px",
                    borderColor: "#E93740",
                    borderWidth: "3px",
                    margin: "0",
                    borderRadius: "5px",
                    opacity: "inherit",
                  }}
                />
                <hr
                  style={{
                    width: "15px",
                    borderColor: "#E93740",
                    borderWidth: "3px",
                    margin: "0",
                    borderRadius: "5px",
                    opacity: "inherit",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="sections">
            <div className="sections-image">
              <img src={business} alt="Placeholder" />
            </div>
            <div className="sections-content">
              <p>
                The implementation of Cync ABL resulted in remarkable
                operational improvements:
              </p>
              <ul>
                <li>
                  <b>92.5% reduction</b> in time to process BBC calculations
                </li>
                <li>
                  <b>2X increase</b> in productivity without additional
                  staffing, with each analyst now handling 60-80 credits
                </li>
                <li>
                  <b>3.3X faster</b> turnaround time for client information
                  processing
                </li>
                <li>
                  Significantly <b>less workload stress</b> for analysts,
                  enhancing their overall job satisfaction
                </li>
              </ul>
            </div>
          </div>
          <br />
        </section>
      </section>

      <section className="testimoni">
        <Carousel
          indicators={false}
          controls={false}
          interval={carouselPlaying ? 15 : null}
          fade={true} // Add fade effect for smooth transition
          className="carousel-container"
        >
          {testimonial.map((testimonial, index) => (
            <Carousel.Item key={index} className="carousel-item">
              <img
                className="d-block w-100 carousel-image"
                src={testimonial.image}
                alt={`Slide ${index + 1}`}
              />
              <Carousel.Caption className="carousel-caption-custom">
                <h1 className="headingwhite">{testimonial.heading}</h1>
                <p className="testimobpara">{testimonial.text}</p>
                <p className="author-text">{testimonial.author}</p>
                <p className="none"></p>
                <p
                  style={{ fontSize: "11.5px" }}
                  className="testimobpara dispara"
                >
                  <i>{testimonial.disclaimer}</i>
                </p>
                <div className="disclaimer">
                  <Button
                    size="sm"
                    variant="light"
                    onClick={() => handleOpen(testimonial.videoUrl)}
                  >
                    Watch Video
                  </Button>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
        <VideoModal open={open} handlemodelClose={handlemodelClose} videoUrl={videoUrl} />
      </section>

      <div className="space"></div>

      <section className="demo-section">
        <div className="demo-content">
          <h1>Supercharge Your Lending Now!</h1>
          <HrLines />
          <div className="space"></div>
          <Button size="small" color="primary" onClick={handleShow}>
            Schedule a Demo
          </Button>
          <UmqPopup show={showPopup} handleClose={handleClose} />
        </div>
      </section>
    </div>
  );
};

export default Umpbank;
