import React, { useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "../../assets/css/insights.css";
import Newsletter from "../NewsLetter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import insightsData from "../../Data/InsightsData";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { isValidPath } from "../../utils/validation";
import { motion } from "framer-motion";

const handleNavigation = (url) => {
  if (isValidPath(url)) {
    window.location.assign(url);
    window.location.reload();
  } else {
    console.warn("Invalid path:", url);
  }
};

const sortedInsightsData = [...insightsData].sort(
  (a, b) => new Date(b.date) - new Date(a.date) // Sort by latest date
);

const Insights = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 6; // Number of posts per page

  // Calculate the indices for the current posts
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = sortedInsightsData.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  // Calculate total pages
  const totalPages = Math.ceil(sortedInsightsData.length / postsPerPage);
  const maxVisiblePages = 5; // Maximum number of visible pages in pagination

  // Dynamic page range calculation
  const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
  const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);
  const adjustedStartPage = Math.max(1, endPage - maxVisiblePages + 1);
  const pageNumbers = Array.from(
    { length: endPage - adjustedStartPage + 1 },
    (_, i) => adjustedStartPage + i
  );

  // Handle page change
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(1, 600); // Scroll to the top of the page
  };

  return (
    <div>
      <Helmet>
        <title>
          Insights on AI-Powered Lending Automation - Cync Software®
        </title>
        <meta
          name="description"
          content="Explore our latest insights on commercial lending, loan origination, factoring, spreading, and financing solutions."
        />
      </Helmet>

      <motion.div
        className="Productright"
        initial={{ opacity: 0, x: 50 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
        viewport={{ once: true, amount: 0.3 }}
      >
        <section className="insights-sections">
          <Container className="h-100">
            <Row className="h-100 align-items-center">
              <Col lg={9}>
                <div className="content">
                  <motion.div
                    className="Productleft"
                    initial={{ opacity: 0, x: -50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.8, ease: "easeOut" }}
                    viewport={{ once: true, amount: 0.7 }}
                  >
                    <h1>Insights</h1>
                  </motion.div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </motion.div>

      <div className="space"></div>

      {/* Posts Section */}
      <Container>
        <Row xs={1} md={3} className="g-4">
          {currentPosts.map((insight) => (
            <Col key={insight.id}>
              <Card className="h-100">
                <p className="date">{insight.date}</p>
                <Link
                  to={insight.buttonLink}
                  onClick={() => handleNavigation(insight.buttonLink)}
                >
                  <Card.Img
                    variant="top"
                    src={`/images/${insight.imageName}`}
                    alt={`Insight ${insight.id}`}
                  />
                </Link>
                <Card.Body className="insightsbody">
                  <div className="tags">
                    {insight.tags.map((tag, index) => (
                      <span key={tag}>
                        <Link to={`/tag/${tag}`} className="tag-link boxtags">
                          {tag}
                        </Link>
                        {index < insight.tags.length - 1 && <span> | </span>}
                      </span>
                    ))}
                  </div>
                  <Link
                    to={insight.buttonLink}
                    onClick={() => handleNavigation(insight.buttonLink)}
                  >
                    <Card.Title>{insight.title}</Card.Title>
                  </Link>
                </Card.Body>
                <Link
                  to={insight.buttonLink}
                  onClick={() => handleNavigation(insight.buttonLink)}
                  className="read-more"
                >
                  {insight.buttonLabel}
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{ margin: "5px", float: "right" }}
                  />
                </Link>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      <div className="space"></div>

      {/* Pagination */}
      <Container>
        <Row>
          <Col className="d-flex justify-content-center">
            <nav>
              <ul className="pagination">
                {/* First Button */}
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={() => paginate(1)}
                    disabled={currentPage === 1}
                  >
                    First
                  </button>
                </li>

                {/* Previous Button */}
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                </li>

                {/* Page Numbers */}
                {pageNumbers.map((page) => (
                  <li
                    key={page}
                    className={`page-item ${
                      currentPage === page ? "active" : ""
                    }`}
                  >
                    <button
                      onClick={() => paginate(page)}
                      className="page-link"
                    >
                      {page}
                    </button>
                  </li>
                ))}

                {/* Next Button */}
                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => paginate(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </li>

                {/* Last Button */}
                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => paginate(totalPages)}
                    disabled={currentPage === totalPages}
                  >
                    Last
                  </button>
                </li>
              </ul>
            </nav>
          </Col>
        </Row>
      </Container>
      <div className="space"></div>

      <Newsletter />
      <div className="space"></div>
    </div>
  );
};

export default Insights;
