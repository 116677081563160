import React from "react";
import "../assets/css/Footer.css"; // Import CSS file for styling
import "@fortawesome/fontawesome-free/css/all.css";
import { Link } from "react-router-dom";
import { isValidPath } from "../utils/validation";

const handleNavigation = (url) => {
  if (isValidPath(url)) {
  window.location.assign(url);
window.location.reload(); 
  } else {
    console.warn('Invalid path:', url);
  }
};

const currentYear = new Date().getFullYear();

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-grid">
        <div className="footer-item">
          <h3>
            {" "}
            <a href="/products"
              onClick={() => handleNavigation("/products")}
              to="/products"
              className="linktext"
            >
              Products
            </a>
          </h3>
          <ul>
            <li>
              {" "}
              <a href="/cync-loan-origination-system-los"
                onClick={() =>
                  handleNavigation("/cync-loan-origination-system-los")
                }
                className="linktext"
              >
                Cync LOS
              </a>
            </li>
            <li>
              <a href="/cync-spreading"
                onClick={() => handleNavigation("/cync-spreading")}
                className="linktext"
              >
                Cync Spreading
              </a>
            </li>
            <li>
              <a href="/cync-abl" onClick={() =>
                  handleNavigation("/cync-abl")
                } className="linktext">Cync ABL</a>
            </li>
            <li>
              <a href="/cync-securities-based-lending" onClick={() =>
                  handleNavigation("/cync-securities-based-lending")
                } className="linktext">Cync SBL</a>
            </li>
            <li>
              <a href="/cync-syndicated-lending" onClick={() =>
                  handleNavigation("/cync-syndicated-lending")
                } className="linktext">Cync Syndicated Lending</a>
            </li>
            
            <li>
              <a href="/cync-factoring"
                onClick={() => handleNavigation("/cync-factoring")}
                className="linktext"
              >
                Cync Factoring
              </a>
            </li>
          
            {/* <li><a href="" onClick={() => handleNavigation('/cync-client')} className="linktext">Cync Client Portal</a></li> */}
          </ul>
        </div>
        

        <div className="footer-item">
          <h3>
            {" "}
            <a href="/products"
              onClick={() => handleNavigation("/products")}
              to="/products"
              className="linktext"
            >
              Resources
            </a>
          </h3>
          <ul>
          <li>
              <a href="/customer-stories" onClick={() =>
                  handleNavigation("/customer-stories")
                } className="linktext">Customer Stories</a>
            </li>
            <li>
              {" "}
              <a href="/insights"
                onClick={() =>
                  handleNavigation("/insights")
                }
                className="linktext"
              >
                Insights
              </a>
            </li>
            
          </ul>
        </div>



        <div className="footer-item">
          <h3>Company</h3>
          <ul>
            <li>
              <a href="/company"
                onClick={() => handleNavigation("/company")}
                to="/company"
                className="linktext"
              >
                About Us
              </a>
            </li>
            <li>
              <a href="/cync-team"
                onClick={() => handleNavigation("/cync-team")}
                to="/cync-team"
                className="linktext"
              >
                Our Team
              </a>
            </li>
            <li>
              <a href="/careers-culture"
                onClick={() => handleNavigation("/careers-culture")}
                to="/careers-culture"
                className="linktext"
              >
                Careers & Culture
              </a>
            </li>
            <li>
              <a href="/news-events"
                onClick={() => handleNavigation("/news-events")}
                to="/news-events"
                className="linktext"
              >
                 News & Events
              </a>
            </li>
            <li>
              <a href="/security"
                onClick={() => handleNavigation("/security")}
                to="/security"
                className="linktext"
              >
                Security
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-item">
          <h3>Contact Us</h3>
          <ul>
            <li>
              <address>
                3505 East Frontage Road, Suite 160 Tampa, FL 33607 <br />
                Tel:{" "}
                <a 
                  href="tel:1-855-367-2962"
                  className="linktext"
                  rel="noopener noreferrer"
                >
                  +1 855-367-2962{" "}
                </a>{" "}
                <br /> E-Mail:{" "}
                <a
                  href="mailto:info@cyncsoftware.com"
                  className="linktext"
                  rel="noopener noreferrer"
                >
                  info@cyncsoftware.com
                </a>
              </address>
            </li>
          </ul>
        </div>
        <div className="footer-item">
          <h3>Follow Us</h3>
          <div className="social-icons">
            <a 
              href="https://www.linkedin.com/company/cyncsoftware"
              target="_blank"
              className="social-icon linktext"
              rel="noopener noreferrer"
            >
              <i className="fab fa-linkedin"></i>
            </a>
            <a
              href="https://www.facebook.com/CyncSoftware/"
              target="_blank"
              className="social-icon linktext"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook-square"></i>
            </a>
            <a
              href="https://x.com/cyncsoftware"
              target="_blank"
              className="social-icon linktext"
              rel="noopener noreferrer"
            >
              <i className="fa-brands fa-square-x-twitter"></i>
            </a>
          </div>
        </div>
      </div>
      <hr className="footer-divider" />
      <div className="footer-bottom">
        <span className="copyright">
          © {currentYear} Cync Software. All Rights Reserved. | Cync® is a registered
          trademark of NDS Systems, LC.
        </span>
        <span className="privacy">
          <a href="/responsible-disclosure-policy" className="linktext"
            onClick={() => handleNavigation("/responsible-disclosure-policy")}
            to="/responsible-disclosure-policy"
          >
            Responsible Disclosure Policy
          </a>{" "}
          |{" "}
          <a href="/cync-reporting-hotline" className="linktext"
            onClick={() => handleNavigation("/cync-reporting-hotline")}
            to="/cync-reporting-hotline"
          >
            Reporting Hotline
          </a>{" "}
          |{" "}
          <a href="/privacy-policy" className="linktext"
            onClick={() => handleNavigation("/privacy-policy")}
            to="/privacy-policy"
          >
            {" "}
            Privacy Policy
          </a>
        </span>
      </div>
    </footer>
  );
};

export default Footer;
