// HomeSection.jsx
import React from "react";
import backgroundImage from "../../assets/background/sblbanner.jpg";
import backgroundImage1 from "../../assets/background/icbabanner.jpg";
import "./HeroSection.css";
import "../../assets/css/home.css";
import Button from "../Button.jsx";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import { isValidPath } from "../../utils/validation.jsx";
import homepagewebp from "../../assets/images/homepage.webp";
import homepageavif from "../../assets/images/homepage.avif";
import { motion } from "framer-motion";

const handleNavigation = (url) => {
  if (isValidPath(url)) {
    window.location.assign(url);
  } else {
    console.warn("Invalid path:", url);
  }
};


const HeroSection = () => {
  return (
    <section className="section-with-carousel">
      <Carousel className="custom-carousel" controls={false} interval={15000} indicators={false}>
      {/* <Carousel.Item>
          <div
            className="section-with-slidebackground firstcar slide-right"
            style={{ backgroundImage: `url(${backgroundImage1})` }}
          >
            <Container className="h-100">
              <Row className="h-100 align-items-center">
                <Col lg={12} style={{justifyContent:'center', display:'flex', top:'231px', position:'relative'}}>
                  <div className="content">
                    <a href="/conference/2025/icba/" onClick={() => handleNavigation("/conference/2025/icba/")}>
                      <Button size="small" color="primary" style={{width:"200px", fontSize:"18px"}}>
                       Book a Meeting
                      </Button>
                    </a>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </Carousel.Item> */}
        {/* <Carousel.Item className="second">
          <div
            className="section-with-slidebackground slide-right"
            style={{ backgroundImage: `url(${backgroundImage})` }}
          >
            <Container className="h-100">
              <Row className="h-100 align-items-center">
                <Col lg={12} style={{justifyContent:'center', display:'flex', top:'200px', position:'relative'}}>
                  <div className="content">
                    <a href="/cync-securities-based-lending/" onClick={() => handleNavigation("/cync-securities-based-lending/")}>
                      <Button size="small" color="primary" style={{width:"200px", fontSize:"18px"}}>
                        Read More
                      </Button>
                    </a>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </Carousel.Item> */}
        <Carousel.Item>
          <div
            className="section-with-background slide-right"
            // style={{ backgroundImage: `url(${backgroundImage})` }}
          >
            <Container className="h-100">
              <Row className="h-100 align-items-center">
                <Col lg={7}>
                  <div className="content">
                    {/* Wrapper for Stagger Animation */}
                    <motion.div
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true, amount: 0.3 }} // Trigger animation when 30% of the content is visible
                      variants={{
                        hidden: { opacity: 0 }, // Initial state for all children
                        visible: {
                          opacity: 1,
                          transition: {
                            staggerChildren: 0.5, // Delay between each child animation
                          },
                        },
                      }}
                    >
                      {/* Animated H1 */}
                      <motion.div
                        variants={{
                          hidden: { opacity: 0, y: -20 }, // Fade and slide up
                          visible: { opacity: 1, y: 0 }, // Fade in and reset position
                        }}
                        transition={{ duration: 1 }}
                      >
                        <h1>Supercharge Your Lending Stack</h1>
                      </motion.div>

                      {/* Animated Paragraph */}
                      <motion.div
                        variants={{
                          hidden: { opacity: 0, y: -20 }, // Fade and slide down
                          visible: { opacity: 1, y: 0 }, // Fade in and reset position
                        }}
                        transition={{ duration: 1 }}
                      >
                        <p style={{ marginTop: "10px", fontSize: "20px" }}>
                          AI-powered automation to reclaim hours in productivity
                          and drive substantial cost savings. Cloud-based with
                          API-led architecture to ensure high resiliency,
                          scalability, and continuous innovation.
                        </p>
                      </motion.div>

                      {/* Animated Button */}
                      <motion.div
                        variants={{
                          hidden: { opacity: 0, y: -30 }, // Fade and slight zoom out
                          visible: { opacity: 1, y: 0 }, // Fade in and reset scale
                        }}
                        transition={{ duration: 1 }}
                      >
                        <a onClick={() => handleNavigation("/request-a-demo")}>
                          <Button size="small" color="primary">
                            Schedule a Demo
                          </Button>
                        </a>
                      </motion.div>
                    </motion.div>
                  </div>
                </Col>
                <Col lg={5} className="topmob" style={{ position: "relative" }}>
                  <motion.div
                    initial={{ opacity: 0, y: 80 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, ease: "easeOut" }}
                    viewport={{ once: true, amount: 0.3 }}
                  >
                    <picture>
                      <source
                        srcSet={homepageavif}
                        type="image/avif"
                        style={{
                          position: "absolute",
                          bottom: "-352px",
                          width: "auto",
                        }}
                      />
                      <source
                        srcSet={homepagewebp}
                        type="image/webp"
                        style={{
                          position: "absolute",
                          bottom: "-352px",
                          width: "auto",
                        }}
                      />
                      <img
                        src={homepagewebp}
                        alt="Bank"
                        style={{
                          position: "absolute",
                          bottom: "-352px",
                          width: "auto",
                        }}
                      />
                    </picture>
                  </motion.div>
                </Col>
              </Row>
            </Container>
          </div>
        </Carousel.Item>
      </Carousel>
    </section>
  );
};

export default HeroSection;
