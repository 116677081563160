import React, { useState } from "react";
import "../../assets/css/home.css";
import "../../assets/css/Products.css";
import { Container, Row, Col } from "react-bootstrap";
import HrLines from "../designs/HrLines";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Accordion from "react-bootstrap/Accordion";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";

const titles = [
  "Cync Loan Origination System",
  "Cync Spreading",
  "Cync Asset-Based Lending",
  "Cync Securities-Based Lending",
  "Cync Syndicated Lending",
  "Cync Factoring",
];
const descriptions = [
  "Highly Flexible and Scalable End-to-End Commercial Loan Origination System to Fast-Track Lending.",
  "AI-Powered Standalone Solution to Revolutionize Financial Spreading Through Automation.",
  "Industry-Leading Asset-Based Lending Platform To Completely Automate Collateral Evaluation and Borrowing Base Calculations.",
  "Comprehensive Solution for Accelerated Lending Against a Diverse Range of Marketable Securities",
  "Highly Flexible, Automated Solution for Simplifying Syndicated Loan Management.",
  "Cloud-Based Factoring Platform to Automate Invoice Validation and Fast-Track Funding to Sellers.",
];
const readMoreLinks = [
  "/cync-loan-origination-system-los",
  "/cync-spreading",
  "/cync-abl",
  "/cync-securities-based-lending",
  "/cync-syndicated-lending",
  "/cync-factoring",
];

const Products = () => {
  const [activeKey, setActiveKey] = useState(null);

  const handleAccordionSelect = (eventKey) => {
    console.log("Accordion selected:", eventKey);
    setActiveKey(eventKey === activeKey ? null : eventKey);
  };
  return (
    <div>
      <Helmet>
        <title>
          Commercial Lending Software | Loan Software - Cync Software®
        </title>
        <meta
          name="description"
          content="Find the right product: Flexible loan origination, asset-based lending, AI-powered financial spreading, and cloud-based factoring platform. Schedule a demo!"
        />
      </Helmet>
      
      <motion.div
        className="Productright"
        initial={{ opacity: 0, x: 50 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
        viewport={{ once: true, amount: 0.3 }}
      >
        <section className="products-sections">
          <Container className="h-100">
            <Row className="h-100 align-items-center">
              <Col lg={9}>
                <div className="content">
                  <motion.div
                    className="Productleft"
                    initial={{ opacity: 0, x: -50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.8, ease: "easeOut" }}
                    viewport={{ once: true, amount: 0.7 }}
                  >
                    <h1>Products</h1>
                  </motion.div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </motion.div>

      <div className="space"></div>

      <section>
        <Container>
          <Row>
            <Col lg={12}>
              <h1 className="mb-4" style={{ textAlign: "center" }}>
                Which Cync® Product is Right for You?
              </h1>
              <HrLines />
            </Col>
          </Row>
        </Container>
      </section>
      <div className="space"></div>

      <Container>
        <Row className="row-cols-md-3 g-4">
          {[1, 2, 3, 4, 5, 6].map((index) => (
            <Col key={index} className="d-flex">
              <div className="boxx d-flex flex-column justify-content-between">
                <div>
                  <h2
                    className="mainhead"
                    style={{
                      color: "#152C5B",
                      fontWeight: "bold",
                      textAlign: "center",
                      marginBottom: "10px",
                    }}
                  >
                    {titles[index - 1]}
                  </h2>
                  <br />
                  <div className="boxx-image">
                    <picture>
                      <source
                        srcSet={`/images/image${index}.avif`}
                        type="image/avif"
                      />
                      <source
                        srcSet={`/images/image${index}.webp`}
                        type="image/webp"
                      />
                      <img
                        src={`/images/image${index}.webp`}
                        alt={`Image ${index}`}
                        style={{ maxWidth: "100%", height: "auto" }}
                      />
                    </picture>
                    {/* <img
                      src={`/images/image${index}.png`}
                      alt={`Image ${index}`}
                      style={{ maxWidth: "100%", height: "auto" }}
                    /> */}
                  </div>
                  <div className="boxx-description">
                    <p className="contentpara" style={{ marginBottom: "10px" }}>
                      {descriptions[index - 1]}
                    </p>
                  </div>
                </div>
                <div>
                  <a
                    href={readMoreLinks[index - 1]}
                    className="read-more"
                    rel="noopener noreferrer"
                  >
                    Read More{" "}
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      style={{ marginLeft: "5px" }}
                    />
                  </a>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
      <div className="space"></div>

      <section className="faqsection">
        <Container>
          <h1 style={{ textAlign: "center" }}>
            Choose a Product That Meets Your Needs
          </h1>
          <HrLines />
          <br />

          <Accordion
            className="faqmain custom-accordion"
            activeKey={activeKey}
            onSelect={handleAccordionSelect}
          >
            <h2>I want to…</h2>

            <Accordion.Item eventKey="0" className="faqbox">
              <Accordion.Header style={{ fontWeight: "bold" }}>
                Automate spreading and analyze cash flow in a
                relationship-focused way.
              </Accordion.Header>
              <Accordion.Body className="contentpara">
                <a href="/spreading">Cync Spreading</a> is a cloud-based,
                relationship-driven product that enables lenders to streamline
                processes, automate financial spreading, and access quick cash
                flow analysis for faster, more accurate decision-making.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1" className="faqbox">
              <Accordion.Header>
                Manage the entire loan lifecycle from application to booking and
                beyond.
              </Accordion.Header>
              <Accordion.Body className="contentpara">
                <a href="/cync-loan-origination-system-los">Cync LOS</a> is a
                cloud-based, relationship-driven product that enables lenders to
                streamline the entire loan process—from “know your customer”
                (KYC) to booking and closing. Cync LOS offers innovative
                features and an intuitive navigation for streamlining and
                automating the loan origination process, providing flexibility,
                saving time, and mitigating the risk of human error.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2" className="faqbox">
              <Accordion.Header>
                Improve ABL portfolio management and automate BBCs.
              </Accordion.Header>
              <Accordion.Body className="contentpara">
                <a href="/cync-abl">Cync ABL</a> application is designed to
                improve a lender’s ABL portfolio management and increase
                productivity through BBC automation. Cync ABL’s reporting,
                trending, and graphs provide complete control and analysis for
                asset-based lenders, enabling them to grow their commercial
                portfolio profitably, all the while minimizing risk and
                improving the borrower’s experience.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3" className="faqbox">
              <Accordion.Header>
                Automate buying, validating, and collecting on invoices.
              </Accordion.Header>
              <Accordion.Body className="contentpara">
                <a href="/spreading">Cync Factoring</a> solution saves lenders
                time and money by automating manual processes. Factoring offers
                the ability to monitor activity and critical data anytime,
                anywhere, and on any device, providing real-time updates for
                improved decision-making and helping reduce fraud and overall
                risk.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4" className="faqbox">
              <Accordion.Header>
                Maintain relationships and analyze each unique borrower.
              </Accordion.Header>
              <Accordion.Body style={{ fontSize: "20px" }}>
                <a href="/cync-factoring">Cync Spreading</a> creates a landing
                page for day-to-day operational activities, giving lenders a
                space to maintain contact information and easily access the
                other feature components specific to that entity while using a
                combination of machine learning and manual flexibility to build
                out the exact spreading template a user needs to most
                effectively analyze each unique borrower.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="5" className="faqbox">
              <Accordion.Header>
                Automate the servicing of both syndicated and participation
                loans.
              </Accordion.Header>
              <Accordion.Body style={{ fontSize: "20px" }}>
                <a href="/cync-syndicated-lending">Cync Syndicated Lending</a>{" "}
                is a highly flexible, automated solution for simplifying the
                management of both syndicated and participation loans. It
                provides lead arrangers, participants, and borrowers with
                exceptional flexibility to structure contracts tailored to their
                unique financing needs and risk management strategies.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="6" className="faqbox">
              <Accordion.Header>
                Manage collateral, exceptions, and covenants and ticklers for
                loans.
              </Accordion.Header>
              <Accordion.Body className="contentpara">
                <a href="/cync-loan-origination-system-los">Cync LOS</a> allows
                lenders to add, manage, and pledge the collateral that will be
                used to secure a loan, grant and log exceptions as needed, and
                create and monitor relationship or loan-specific covenants and
                ticklers.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="7" className="faqbox">
              <Accordion.Header>
                Automatically calculate ineligibles and disbursements or
                advances.
              </Accordion.Header>
              <Accordion.Body className="contentpara">
                <a href="/cync-abl">Cync ABL</a> allows lenders to efficiently
                and effectively make calculations based on the borrower’s
                collateral, which usually consists of AR, inventory, and
                equipment. The application is designed to improve the lenders’
                ABL portfolio management and increase productivity through BBC
                automation, automatically calculating ineligibles by simply
                uploading a file to the application.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="8" className="faqbox">
              <Accordion.Header>
                Automate and streamline the portfolio management process.
              </Accordion.Header>
              <Accordion.Body className="contentpara">
                <a href="/cync-factoring">Cync Factoring</a> covers the entire
                Portfolio Management process, which includes uploading and
                validating invoices, verification letter tracking, and
                calculating and recording advances, reserves, and fees. Cync
                Factoring supports cash batch entry, tracks reserve balance
                activity upon payment processing, and has export capabilities to
                accounting General Ledgers.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Container>
      </section>
    </div>
  );
};
export default Products;
