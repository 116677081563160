import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from 'react-helmet';
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { datadogRum } from '@datadog/browser-rum';


// function getLastParameter() {
//   const query = window.location.search; 
//   if (query.startsWith('?')) {
//     const param = query.split('&')[0].substring(1); 
//     return param || null; 
//   }
//   return null; 
// }


function getLastParameter() {
  const query = window.location.search; // Get the query string (e.g., ?comm=12345&other=xyz)
  const params = new URLSearchParams(query);
  return params.get("comm") || null; // Get value of 'comm' parameter
}

// Fetch the user's IP address
fetch('https://api.ipify.org?format=json')
  .then((response) => response.json())
  .then((data) => {
    const userIp = data.ip; 
    const userId = getLastParameter();

    // Initialize Datadog RUM
    datadogRum.init({
      applicationId: "f8cccee9-6312-49cb-b40b-7c64e45d5343", 
      clientToken: "pub6f9dbdb85b49ae326fc50214841c0dc8", 
      site: "datadoghq.com",
      service: "cync-website", // Replace with your service name
      env: "dev", // Environment (e.g., 'prod', 'staging')
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      defaultPrivacyLevel: "mask-user-input",
      startSessionReplayRecordingManually: false,
    });

    console.log("Datadog RUM initialized successfully.");

    // Send user details to Datadog only if a valid userId exists
    if (userId) {
      datadogRum.setUser({
        id: userId, // The user ID extracted from the URL
        ip: userIp, // User's IP address
      });
      console.log(`User ID sent to Datadog: ${userId}`);
    } else {
      console.warn('No valid user ID found in the URL.');
    }

    // Start session replay recording
    datadogRum.startSessionReplayRecording();
  })
  .catch((error) => {
    console.error('Error fetching user IP or initializing Datadog RUM:', error);
  });




  //   // Send user details to Datadog only if a valid userId exists
  //   if (userId) {
  //     datadogRum.setUser({
  //       id: userId, // The user ID extracted from the URL
  //       ip: userIp, // User's IP address
  //     });
  //     console.log(`User ID sent to Datadog: ${userId}`);
  //   } else {
  //     console.warn('No valid user ID found in the URL.');
  //   }
  //   // Start RUM session recording
  //   datadogRum.startSessionReplayRecording();
  // })
  // .catch((error) => {
  //   console.error('Error fetching user IP or initializing Datadog RUM:', error);
  // });


// if (user.isAuthenticated) {
//   window.DD_RUM.startSessionReplayRecording();
// }

const root = createRoot(document.getElementById('root'));
root.render(
  <StrictMode>
      <GoogleReCaptchaProvider reCaptchaKey="6LdKfxgjAAAAAPO0jJTu6rbM0q8X8agpHUjBDsiA">
    <App />
    </GoogleReCaptchaProvider>
  </StrictMode>
);

