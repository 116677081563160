const parabilistestimonial = [
    {
        image: '/background/testimonialbg2.png',
        heading: 'Customer Testimonial',
        text: '“Before Cync Software, our loan processing was entirely manual and Excel-based. We needed a cloud-based solution that would reduce the potential for error, improve the user experience, and provide the automation we needed. Cync Software really went above and beyond by allowing us to customize a solution to better fit our growing portfolio.”',
        author: '- Evan Henris, Director - Portfolio Analytics, Parabilis',
        videoUrl: 'https://www.cyncsoftware.com/videos/parabilis.mp4',
        pageUrl: '/customer-stories/parabilis',
      },
  ];

  export default parabilistestimonial;
  