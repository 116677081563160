import React, { useEffect, useRef } from 'react';
import { Modal, Box, IconButton } from '@mui/material';
import { IoClose } from 'react-icons/io5';


const VideoModal = ({ open, handlemodelClose, videoUrl }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (open && videoRef.current) {
      videoRef.current.load(); // Ensure it loads the new dynamic URL
      const playPromise = videoRef.current.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => console.log("Autoplay started"))
          .catch((error) => console.error("Autoplay blocked:", error));
      }
    }
  }, [open, videoUrl]); // Dependency array includes `videoUrl` to handle dynamic updates

  // Function to close modal and stop video
  const handleModalClose = () => {
    if (videoRef.current) {
      videoRef.current.pause(); // Stop video
      videoRef.current.currentTime = 0; // Reset video to start
    }
    handlemodelClose(); // Close modal
  };

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-labelledby="video-modal-title"
      aria-describedby="video-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80vw',
          height: '60vh',
          maxWidth: '1000px',
          maxHeight: '90vh',
          bgcolor: 'black',
          boxShadow: 24,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '10px',
          outline: 'none',
        }}
      >
 {/* Close Button - Now Works Correctly */}
 <IconButton
    onClick={handleModalClose}
    sx={{
      position: 'absolute',
      fontSize: "13px",
      top: 10,
      right: 10,
      color: 'white',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
      },
      zIndex: 10, // Ensure it's above everything
    }}
  >
    ✖
  </IconButton>
        {/* Video Player */}
        {videoUrl ? (
          <video
            ref={videoRef}
            key={videoUrl} // Key forces re-render when video URL changes
            src={videoUrl}
            controls
            autoPlay
            playsInline
            controlsList="nodownload"
            style={{
              width: '100%',
              height: '100%',
              maxWidth: '1000px',
              maxHeight: '90vh',
              borderRadius: '10px',
            }}
          />
        ) : (
          <p style={{ color: 'white' }}>No video available</p>
        )}
      </Box>
    </Modal>
  );
};

export default VideoModal;
