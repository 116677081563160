import React from "react";
import "../../assets/css/SpreadingStepBoxes.css";

const SpreadingStepBoxes = () => {
  return (
    <div className="roadmap-container">
      <div className="roadmap-box left-box box1">
        <h2 style={{ color: "#2971EB" }}>
          Fast-Track Spreading with Automation
        </h2>
        <p>
          Choose a template from your library, aligned with the borrower's
          industry and profile. Effortlessly customize the chosen template with
          a no-code, self-service approach to meet specific borrower needs for
          accurate financial analysis. Harness AI-powered extraction to quickly
          spread data from PDFs, CSVs, images, GAAP, and non-GAAP statements.
          Automate financial data aggregation from related entities for Global
          Debt Service analysis, compute standard and custom ratios in seconds,
          and streamline covenant evaluations—minimizing manual tasks and
          boosting operational efficiency.
        </p>
        <div className="arrow left-arrow"></div>
      </div>
      <div className="roadmap-box right-box box2">
        <h2 style={{ color: "#2971EB" }}>
          Optimized Financial Spreading for CRE Lending{" "}
        </h2>
        <p>
          Whether your borrowers own multi-family, mixed-use, industrial, or
          other real estate assets, Cync Spreading makes spreading financials
          incredibly easy. Select a template from your library and customize it
          to support document types such as rent rolls or specialized cash flow
          statements aligned with the borrower’s portfolio. Next, upload cash
          flow statements, rent rolls, and other documents in a variety of
          formats to automatically extract data into a GAAP-compliant format and
          compute key ratios like Net Operating Income (NOI), Debt Service
          Coverage, and Break-even Occupancy Rate. Proactively mitigate risks
          with rollover analysis, tenant lease exposure, financial projections,
          and stress testing. Anticipate lease renewal risks, tenant
          concentration vulnerabilities, income fluctuations, and lease
          expiration exposure while modeling real-world scenarios—such as rental
          declines, rising vacancies, and interest rate shifts—to drive smarter
          lending decisions.
        </p>
        <div className="arrow right-arrow"></div>
      </div>
      <div className="roadmap-box left-box box3 spreadingbox">
        <h2 style={{ color: "#2971EB" }}>
          Optimized Financial Spreading for C&I Lending
        </h2>
        <p>
          Whether your borrowers operate in manufacturing, retail, healthcare,
          or other industries, Cync Spreading simplifies financial spreading for
          C&I lending. Select a template from your library and customize it to
          accommodate financial statement structures unique to the borrower's
          business. Upload financial statements, tax returns, general ledgers
          from accounting software, and other financial documents in a variety
          of formats to automatically extract data into a GAAP-compliant
          structure and compute key ratios like Debt Service Coverage, EBITDA
          Margins, and Working Capital Ratios. Leverage common sizing to compare
          financial performance across periods, identify trends, and benchmark
          against industry peers with clarity. Additionally, analyze global cash
          flow by spreading financials for individuals, such as small business
          owners, combining business and personal income and expenses.
        </p>
        <div className="arrow left-arrow"></div>
      </div>

      {/* Vertical connecting line */}
      <div className="vertical-line"></div>
    </div>
  );
};

export default SpreadingStepBoxes;
