const testimonial = [
    {
      image: '/background/testimonialbg.png',
      heading: 'Customer Testimonial',
      text: '“The upload to the BBC calculation went from 4 hours manually per client to 18 minutes with Cync—this was a huge efficiency gain for us. Also, getting information through to clients has gone from 10 days per month for turn days down to 3-senior management has been very happy with these results.”',
      author: '- Errin Richardson, Managing Director Asset-Based Lending, Umpqua Bank*',
      videoUrl: 'https://www.cyncsoftware.com/videos/umpqua-bank.mp4',
      disclaimer:'* Following its merger with Columbia Bank in March 2023, Umpqua Bank continues to expand Cync ABL adoption across the organization.',
      pageUrl: '/customer-stories/umpqua-bank',
    },
  ];

  export default testimonial;
  