import React, { useState, useEffect } from "react";
import "../../assets/css/CyncSpreading.css";
import { Container, Row, Col, Card, Carousel } from "react-bootstrap";
import Button from "../Button";
import HrLines from "../designs/HrLines";
import SpreadingPopup from "../popup/LOS/SpreadingPopup.jsx";
import VideoModal from "../VideoModal";
import testimonials from "../../Data/testimonialsData.js";
import { spreadinboxes } from "../../Data/boxesData.js";
import reverse from "../../assets/icons/reverse.png";
import { Helmet } from "react-helmet";
import SpreadingStepBoxes from "../StepBoxes/SpreadingStepBoxes.jsx";
import { useNavigate } from "react-router-dom";
import spreadingwebp from "../../assets/images/spreading.webp";
import spreadingavif from "../../assets/images/spreading.avif";
import { motion } from "framer-motion";

const flipBox = (id) => {
  const clickedBox = document.getElementById(`box-${id}`);
  const allBoxes = document.querySelectorAll(".box");

  // Remove 'flipped' class from all boxes except the clicked one
  allBoxes.forEach((box) => {
    if (box !== clickedBox) {
      box.classList.remove("flipped");
    }
  });

  // Toggle 'flipped' class for the clicked box
  clickedBox.classList.toggle("flipped");
};

const CyncSpreading = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [lastActiveStep, setLastActiveStep] = useState(0);

  const handleStepClick = (index) => {
    if (activeStep === index) {
      setActiveStep(null);
    } else {
      setActiveStep(index);
      setLastActiveStep(index);
    }
  };

  const [showPopup, setShowPopup] = useState(false);

  const navigate = useNavigate();

  const handleShow = () => {
    // Leadfeeder tracking
    window.history.pushState({}, "", "/cync-spreading/form");
    // Push event to Google Analytics / Leadfeeder (if using Google Tag Manager)
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "popup_opened",
    });

    // If using Google Analytics (GA4)
    if (window.gtag) {
      window.gtag("event", "popup_opened", {
        event_category: "Popup",
        event_label: "Popup Form Opened",
      });
    }

    setShowPopup(true);
  };
  const handlemodelClose = () => {
    setOpen(false);
    setVideoUrl("");
    setCarouselPlaying(true);
  };

  const handleClose = () => {
    navigate(-1);
    setShowPopup(false);
    setOpen(false);
    setVideoUrl("");
    setCarouselPlaying(true);
  };

  const [open, setOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [carouselPlaying, setCarouselPlaying] = useState(true);

  const handleOpen = (url) => {
    setVideoUrl(url);
    setOpen(true);
    setCarouselPlaying(false);
  };

  const imageContainerStyle = {
    minHeight: "", // Set a minimum height for the image container
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "20px", // Adjust as needed
    overflow: "hidden", // Hide overflow to prevent abrupt resizing
    transition: "height 0.3s ease-in-out", // Smooth transition for height changes
    height: activeStep !== null ? "420px" : "300px", // Dynamic height based on activeStep
  };

  return (
    <div>
      <Helmet>
        <title>
          Automated Financial Spreading | Financial Spreading Software - Cync
          Software®
        </title>
        <meta
          name="description"
          content="Transform financial spreading with AI-powered automation, offering custom templates and streamlined productivity gains. Schedule a demo!"
        />
      </Helmet>
      <section className="spreadingtop-sections">
        <Container className="h-100">
          <Row className="h-100 align-items-center">
            <Col lg={7}>
              <div className="content">
                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.3 }} // Trigger animation when 30% of the content is visible
                  variants={{
                    hidden: { opacity: 0 }, // Initial state for all children
                    visible: {
                      opacity: 1,
                      transition: {
                        staggerChildren: 0.5, // Delay between each child animation
                      },
                    },
                  }}
                >
                  <motion.div
                    variants={{
                      hidden: { opacity: 0, y: -20 }, // Fade and slide up
                      visible: { opacity: 1, y: 0 }, // Fade in and reset position
                    }}
                    transition={{ duration: 1 }}
                  >
                    <h4
                      style={{
                        color: "#E93740",
                        fontSize: "50px",
                        fontWeight: "700",
                      }}
                    >
                      Cync Spreading
                    </h4>
                    <h1 className="lostopheading">
                      AI-Powered Solution to Automate Financial Spreading and
                      Unlock Actionable Insights
                    </h1>
                  </motion.div>
                  <motion.div
                    variants={{
                      hidden: { opacity: 0, y: -20 }, // Fade and slide down
                      visible: { opacity: 1, y: 0 }, // Fade in and reset position
                    }}
                    transition={{ duration: 1 }}
                  >
                    <p className="toppara">
                      Hit the ground running with master templates designed for
                      Commercial & Industrial (C&I), Commercial Real Estate
                      (CRE), and individual borrowers. Effortlessly customize
                      templates with a no-code, self-service approach to meet
                      your specific needs. Pivot to a highly automated and
                      accurate spreading process to reclaim hours of back-office
                      productivity while improving oversight with actionable
                      insights and intelligent reporting.
                    </p>
                  </motion.div>
                  <motion.div
                    variants={{
                      hidden: { opacity: 0, y: -30 }, // Fade and slight zoom out
                      visible: { opacity: 1, y: 0 }, // Fade in and reset scale
                    }}
                    transition={{ duration: 1 }}
                  >
                    <Button size="small" color="primary" onClick={handleShow}>
                      Product Demo
                    </Button>
                  </motion.div>
                </motion.div>
              </div>
            </Col>
            <Col lg={5} className="topmob" style={{ position: "relative" }}>
              <motion.div
                initial={{ opacity: 0, y: 80 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, ease: "easeOut" }}
                viewport={{ once: true, amount: 0.3 }}
              >
                <picture>
                  <source
                    srcSet={spreadingavif}
                    type="image/avif"
                    style={{
                      position: "absolute",
                      bottom: "-299px",
                      width: "auto",
                    }}
                  />
                  <source
                    srcSet={spreadingwebp}
                    type="image/webp"
                    style={{
                      position: "absolute",
                      bottom: "-299px",
                      width: "auto",
                    }}
                  />
                  <img
                    src={spreadingwebp}
                    alt="Bank"
                    style={{
                      position: "absolute",
                      bottom: "-299px",
                      width: "auto",
                    }}
                  />
                </picture>
              </motion.div>
            </Col>
          </Row>
        </Container>
      </section>

      <div className="space"></div>

      <section>
        <Container className="h-100">
          <h1 className="privacyheading">
            Supercharge Your Lending With Cync Spreading
          </h1>
          <HrLines />
          <br />
          <p className="contentpara">
            Manual financial spreading weighs down analysts, portfolio managers,
            and loan officers, whether they are processing, underwriting,
            monitoring, or restructuring loans. The entire process is not only
            time-consuming due to extensive data entry and analysis but also
            carries significant risks of errors and inaccuracies. Additionally,
            it lacks the scalability needed to handle large volumes efficiently.
          </p>
          <p className="contentpara">
            Stop the manual grunt work and say hello to Cync Spreading, an
            AI-driven, highly automated solution that spreads financials for
            C&I, CRE, and individual borrowers in minutes with impressive
            accuracy and delivers powerful insights that fuel business
            decisions.
          </p>
        </Container>
      </section>

      <div className="space"></div>

      <section className="benefits-with-grids">
        <div className="benefitsgrid" style={{ width: "375px" }}>
          <div className="icon">
            <img
              src={require("../../assets/icons/improvement.png")}
              alt="Icon 1"
            />
          </div>
          <div className="text">
            <h6>4X</h6>
            <h5>Back-Office Productivity Improvement</h5>
          </div>
        </div>
        <div className="benefitsgrid" style={{ width: "375px" }}>
          <div className="icon">
            <img
              src={require("../../assets/icons/development.png")}
              alt="Icon 2"
            />
          </div>
          <div className="text">
            <h6>0%</h6>
            <h5>Development Required to Customize Spreading Templates</h5>
          </div>
        </div>
        <div className="benefitsgrid" style={{ width: "375px" }}>
          <div className="icon">
            <img src={require("../../assets/icons/40.png")} alt="Icon 2" />
          </div>
          <div className="text">
            <h6>40+</h6>
            <h5>Ready-to-Use Reports with Customization Options</h5>
          </div>
        </div>
      </section>
      <div className="space"></div>

      <section>
        <Container>
          <h1 className="privacyheading">Why Cync Spreading?</h1>
          <HrLines />
          <div className="space"></div>
          <SpreadingStepBoxes />
        </Container>
      </section>

      <div className="space"></div>

      <section>
        <Container>
          <h1 className="privacyheading">Expansive Feature Set</h1>
          <HrLines />
          <div className="space"></div>
          <Row className="box-row">
            {spreadinboxes.map((box) => (
              <Col key={box.id} md={3} sm={6} xs={12} className="box-col">
                <Card
                  className="box"
                  id={`box-${box.id}`}
                  onClick={() => flipBox(box.id)}
                >
                  <div className="front">
                    <div className="icon-top-right">
                      <img src={reverse} alt="Extra Icon" />
                    </div>
                    <img src={box.icon} alt={box.title} />
                    <br />
                    <h3>{box.title}</h3>
                  </div>
                  <div className="back">
                    <p>{box.description}</p>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <div className="space"></div>

      <section className="testimoni">
        <Carousel
          indicators={true}
          controls={false}
          interval={carouselPlaying ? 15000 : null}
          fade={true} // Add fade effect for smooth transition
          className="carousel-container"
        >
          {testimonials.map((testimonial, index) => (
            <Carousel.Item key={index} className="carousel-item">
              <img
                className="d-block w-100 carousel-image"
                src={testimonial.image}
                alt={`Slide ${index + 1}`}
              />
              <Carousel.Caption className="carousel-caption-custom">
                <h1 className="headingwhite">{testimonial.heading}</h1>
                <p className="testimobpara">{testimonial.text}</p>
                <p className="author-text">{testimonial.author}</p>
                <p className="none"></p>
                <p
                  style={{ fontSize: "11.5px" }}
                  className="testimobpara dispara"
                >
                  <i>{testimonial.disclaimer}</i>
                </p>
                <div
                  className="disclaimer"
                  style={{ gap: "10px", display: "flex" }}
                >
                  <Button
                    size="sm"
                    variant="light"
                    onClick={() => handleOpen(testimonial.videoUrl)}
                  >
                    Watch Video
                  </Button>
                  {(index === 0 || index === 1 || index === 2) && (
                    <a href={testimonial.pageUrl}>
                      <Button size="sm" variant="light">
                        Read More
                      </Button>
                    </a>
                  )}
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
        <VideoModal
          open={open}
          handlemodelClose={handlemodelClose}
          videoUrl={videoUrl}
        />
      </section>

      <div className="space"></div>

      <section className="demo-section">
        <div className="demo-content">
          <h1>Supercharge Your Financial Spreading Now!</h1>
          <HrLines />
          <div className="space"></div>
          <Button size="small" color="primary" onClick={handleShow}>
            Product Demo
          </Button>
          <SpreadingPopup show={showPopup} handleClose={handleClose} />
        </div>
      </section>
    </div>
  );
};

export default CyncSpreading;
