import React from "react";
import "../../assets/css/SBLStepBoxes.css";

const SBLStepBoxes = () => {
  return (
    <div className="roadmap-container">
      <div className="roadmap-box left-box box1 sblbox1">
        <h2 style={{ color: "#2971EB" }}>
          Maximize Opportunities by Lending Against a Diverse Range of
          Marketable Securities{" "}
        </h2>
        <p>
          Provide your borrowers with a higher credit limit by allowing them an
          option to pledge a diverse range of assets, including stocks,
          Exchange-Traded Funds (ETFs), mutual funds, bonds, money market
          instruments, and Real Estate Investment Trusts (REITs), along with
          other marketable securities traded on top global stock exchanges.
        </p>
        <div className="arrow left-arrow"></div>
      </div>
      <div className="roadmap-box right-box box2 sblbox2">
        <h2 style={{ color: "#2971EB" }}>Superfast Underwriting Decisions </h2>
        <p>
          Equip loan advisors to accurately assess credit limits against
          marketable securities and enable underwriters to expedite the process
          with precision. Enhance efficiency and streamline decision-making to
          deliver swift, reliable loan evaluations, ensuring prompt approvals
          and quick funding for borrowers.
        </p>
        <div className="arrow right-arrow"></div>
      </div>
      <div className="roadmap-box left-box box3 sblbox3">
        <h2 style={{ color: "#2971EB" }}>Essential Risk Mitigation Tools</h2>
        <p>
          Set Loan-to-Value (LTV) ratios at both the asset class and individual
          ticker levels to establish buffer zones in line with your risk
          policies. Enforce maximum price limits to automatically exclude
          ineligibles from the credit limit. Monitor portfolios at the
          individual client level to identify borrowers approaching LTV
          thresholds or in default, and at your overall portfolio level to
          mitigate concentration risk and identify high-risk assets.
        </p>
        <div className="arrow left-arrow"></div>
      </div>
      <div className="roadmap-box right-box box4 sblbox4">
        <h2 style={{ color: "#2971EB" }}>
          Lend Against a Variety of Asset Types Beyond Marketable Securities{" "}
        </h2>
        <p>
          Have borrowers with an asset mix beyond marketable securities?
          Seamlessly integrate with Cync ABL to lend against accounts
          receivable, inventory, real estate, equipment, government contracts
          (GovCon), and agricultural assets. Streamline operations further by
          automating Borrowing Base Certificate (BBC) generation.
        </p>
        <div className="arrow right-arrow"></div>
      </div>
      <div className="roadmap-box left-box box5 sblbox5">
        <h2 style={{ color: "#2971EB" }}>
          End-To-End Securities-Based Lending Solution{" "}
        </h2>
        <p>
          Integrate with your existing core banking system or leverage the
          inbuilt loan servicing module to easily track balances, calculate
          interest and amortization, process payments, and ensure compliance.
          Additionally, enhance borrower experience and accelerate onboarding
          through seamless integration with Cync LOS.
        </p>
        <div className="arrow left-arrow"></div>
      </div>

      {/* Vertical connecting line */}
      <div className="vertical-line"></div>
    </div>
  );
};

export default SBLStepBoxes;
