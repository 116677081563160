import innovation from "../assets/icons/innovation.png";
import integrity from "../assets/icons/integrity.png";
import agility from "../assets/icons/agility.png";
import centricity from "../assets/icons/centricity.png";
import social from "../assets/icons/social.png";
import empower from "../assets/icons/empower.png";
import risk from "../assets/icons/risk.png";
import three from "../assets/icons/360.png";
import automated from "../assets/icons/automated.png";
import effort from "../assets/icons/effort.png";
import colletral from "../assets/icons/colletral.png";
import protective from "../assets/icons/protective.png";
import thirdparty from "../assets/icons/thirdparty.png";
import topnatch from "../assets/icons/topnatch.png";
import microservices from "../assets/icons/microservices.png";
import nocode from "../assets/icons/nocode.png";
import easy from "../assets/icons/easy.png";
import secure from "../assets/icons/secure.png";
import highprodcut from "../assets/icons/highprodcut.png";
import effortless from "../assets/icons/effortless.png";
import trends from "../assets/icons/trends.png";
import portfolio from "../assets/icons/portfolio.png";
import borrow from "../assets/icons/borrow.png";
import level from "../assets/icons/level.png";
import portal from "../assets/icons/portal.png";
import software from "../assets/icons/software.png";
import trackdoc from "../assets/icons/trackdoc.png";
import funding from "../assets/icons/funding.png";
import email from "../assets/icons/email.png";
import decesion from "../assets/icons/decesion.png";
import robust from "../assets/icons/robust.png";
import document from "../assets/icons/document.png";
import imports from "../assets/icons/import.png";
import currency from "../assets/icons/currency.png";
import secureprivacy from "../assets/icons/secureprivacy.png";
import collab from "../assets/icons/collab.png";
import timesaveblue from "../assets/icons/timesaveblue.png";
import rim from "../assets/icons/rim.png";
import scm from "../assets/icons/scm.png";
import rvm from "../assets/icons/rvm.png";
import pim from "../assets/icons/pim.png";
import sam from "../assets/icons/sam.png";
import sdcm from "../assets/icons/sdcm.png";
import sac from "../assets/icons/sac.png";
import esw from "../assets/icons/esw.png";
import cpt from "../assets/icons/cpt.png";
import acp from "../assets/icons/acp.png";
import sedp from "../assets/icons/sedp.png";
import comprehensive from "../assets/icons/comprehensive.png";
import realtime from "../assets/icons/realtime.png";
import scalable from "../assets/icons/scalable.png";
import defaults from "../assets/icons/defaults.png";
import flexiblefee from "../assets/icons/flexiblefee.png";
import paymentprocess from "../assets/icons/paymentprocess.png";
import sofr from "../assets/icons/sofr.png";
import loanagreement from "../assets/icons/loanagreement.png";
import colletraldemand from "../assets/icons/colletraldemand.png";
import ledger from "../assets/icons/ledger.png";
import template from "../assets/icons/template.png";
import sbl1 from "../assets/icons/sbl1.png"
import sbl2 from "../assets/icons/sbl2.png"
import sbl3 from "../assets/icons/sbl3.png"
import sbl4 from "../assets/icons/sbl4.png"
import sbl5 from "../assets/icons/sbl5.png"
import sbl6 from "../assets/icons/sbl6.png"
import sbl7 from "../assets/icons/sbl7.png"
import sbl8 from "../assets/icons/sbl8.png"
import sbl9 from "../assets/icons/sbl9.png"
import multi from "../assets/icons/multicash.png"
import ai from "../assets/icons/ai.png"
import com from "../assets/icons/com.png"
import cal from "../assets/icons/cal.png"
import bench from "../assets/icons/bench.png"
import stress from "../assets/icons/stress.png"

const boxes = [
  {
    id: 1,
    title: "Revolutionary Risk Rating",
    description:
      "Two-dimensional risk rating capability that calculates not only write-off in case of default but also probability of default. Available to loan officers via an easy-to-understand scorecard. ",
    icon: risk,
  },
  {
    id: 2,
    title: "Automated Financial Spreading",
    description:
      "Import or upload financial documents and swiftly generate spread financials using our game-changing artificial intelligence. ",
    icon: integrity,
  },
  {
    id: 3,
    title: "360º View into Relationships",
    description:
      "Identify affiliations between entities and mitigate risks by tracking financial transactions between them.",
    icon: three,
  },
  {
    id: 4,
    title: "Automated Covenants & Tickler Management",
    description:
      "Ensure lender-borrower transparency as well as compliance via automated covenants and tickler management.",
    icon: automated,
  },
  {
    id: 5,
    title: "Effortless File Management",
    description:
      "Securely store documents for tracking, compliance, and accountability. Add metadata to facilitate quick and easy retrieval.",
    icon: effort,
  },
  {
    id: 6,
    title: "Collateral Pledging & Monitoring",
    description:
      "Employ rules to calculate ratios and conduct in-depth analysis to track portfolio performance.",
    icon: colletral,
  },
  {
    id: 7,
    title: "Proactive Exception Handling",
    description:
      "Track and document deviations from standard policies to ensure integrity and improve efficiency in loan approval.  ",
    icon: protective,
  },
  {
    id: 8,
    title: "Integrate with Popular Third Parties",
    description:
      "Seamlessly connect with third parties via open APIs to foster innovation and improve customer experience.",
    icon: thirdparty,
  },
];

const ablboxes = [
  {
    id: 1,
    title: "Streamlined Portfolio Management & Servicing",
    description: (
      <div style={{ fontSize: "16px" }}>
        Post cash manually or automatically by receiving a file from the
        lockbox, along with check images. Collect cash in bulk, by account
        debtor, or by invoice. Manage adjustments and credit memos, as well as
        inquiries and reports. Instantly generate real-time interest and fee
        statements and send them directly to borrowers via email.
      </div>
    ),
    icon: easy,
  },
  {
    id: 2,
    title: "Detailed Trend Cards",
    description:
      "Zoom in to the granular level and understand trends in borrowing base, collateral valuation, underwriting, and ineligibles.",
    icon: trends,
  },
  {
    id: 3,
    title: "Identify Single Debtor Across Portfolio",
    description:
      "Recognize affiliations to determine exposure to a single debtor and manage concentration risks effectively.",
    icon: portfolio,
  },
  {
    id: 4,
    title: "Work With Multiple Currencies",
    description:
      "Perform multi-currency conversions upon uploading collateral files for borrowing base calculations.",
    icon: currency,
  },
  {
    id: 5,
    title: "Expand Lending Capabilities",
    description:
      "Expand your offerings beyond asset-based loans to include term loans, letters of credit, credit lines, and more.",
    icon: effort,
  },
  {
    id: 6,
    title: "Borrower Roles and Permissions",
    description:
      "Assign access, create, edit, or delete permissions for each role at a feature level, based on their responsibilities and technical proficiency.",
    icon: borrow,
  },
  {
    id: 7,
    title: "Multiple Approval Levels",
    description:
      "Enforce controls for transactions and data access, as well as separate duties based on roles and permissions, to mitigate risks, improve compliance, and enhance oversight.",
    icon: level,
  },
  {
    id: 8,
    title: "Borrower Portal for Seamless Communication",
    description:
      "Enable borrowers to upload or import collateral files, access loan statements or request funds. Customize the portal with your logo for a consistent user experience and include your privacy and disclosure policies.",
    icon: portal,
  },
  {
    id: 9,
    title: "Integrate With Borrower’s Accounting Software",
    description:
      "Open API architecture enables lenders to seamlessly import accounts receivable and payable entries from over 50 popular accounting software platforms.",
    icon: software,
  },
  {
    id: 10,
    title: "Track Documents & Send Reminders",
    description:
      "Monitor borrower-submitted documents or send reminders via text or email when they are due. Maintain a comprehensive record to be audit-ready.",
    icon: trackdoc,
  },
  {
    id: 11,
    title: "Integrate With Your Banking Core",
    description:
      "Import borrowers and loan balances directly from your core system via API integration. Push funding and borrowing base availability back to the core in real time.",
    icon: thirdparty,
  },
];

const spreadinboxes = [
  {
    id: 1,
    title: "Multiple Cash Flow Analysis Methods",
    description:
      "Choose between Uniform Credit Analysis (UCA), Direct, Indirect, and Traditional cash flow methods to assess a borrower's cash flow capacity and financial risks based on preferred methodology or borrower type.",
    icon: multi,
  },
  {
    id: 2,
    title: "AI-Powered Financial Spreading",
    description:
      "Import or upload financial documents and swiftly spread financials with precision using our game-changing artificial intelligence. ",
    icon: ai,
  },
  {
    id: 3,
    title: "Comprehensive Analysis With Custom Ratios",
    description:
      "Use a combination of standard ratios and custom-defined ratios specific to industries, borrower types, or loan products to gain a comprehensive understanding of the borrower’s liquidity, leverage, and profitability.",
    icon: com,
  },
  {
    id: 4,
    title: "No-Code, Self-Service Templates",
    description:
      "Take full control of template creation and customization, ensuring seamless alignment with borrower-specific or industry-specific financial structures—no professional services required.",
    icon: easy,
  },
  {
    id: 5,
    title: "Automated Covenants & Tickler Management",
    description:
      "Fast-track lender-borrower communication while enhancing transparency and compliance with automated covenants and tickler management.",
    icon: automated,
  },
  {
    id: 6,
    title: "Accurately Spread 24 Months of Data in One Pass",
    description:
      "Accurately spread 24 months—or more if needed—of financial data in one pass for unmatched efficiency and agility.",
    icon: cal,
  },
  {
    id: 7,
    title: "Benchmark Current vs. Prior Financials",
    description:
      "View and compare against prior periods’ financials while spreading current year’s financial statements.",
    icon: bench,
  },
  {
    id: 8,
    title: "Robust Stress Testing",
    description:
      "Apply diverse scenarios to evaluate borrower resilience, identify risks, and strengthen credit decisions under adverse conditions.",
    icon: stress,
  },
  {
    id: 9,
    title: "Multi-Format Support",
    description:
      "Automatically extract and spread information from spreadsheets, PDFs, CSVs, and even images, regardless of whether they adhere to the GAAP format.",
    icon: effort,
  },
  {
    id: 10,
    title: "360º View Into Relationships",
    description:
      "Automatically detect financial relationships between entities to effortlessly assess consolidated debt obligations and overall repayment capacity.",
    icon: three,
  },
  {
    id: 11,
    title: "Revolutionary Risk Rating",
    description:
      "Two-dimensional risk rating capability that calculates not only write-off in case of default but also probability of default. Available as an optional component.",
    icon: protective,
  },
  {
    id: 12,
    title: "Effortless File Management",
    description:
      "Securely store documents for tracking, compliance, and accountability. Add metadata to facilitate quick and easy retrieval.",
    icon: document,
  },
  {
    id: 13,
    title: "Reporting As a Service",
    description:
      "Set up your dashboard by selecting from a wide range of reports or requesting a custom report tailored to your specific information needs.",
    icon: robust,
  },
  {
    id: 14,
    title: "Borrower Portal for Seamless Communication",
    description:
      "Set up reminders to notify borrowers about tasks, enable them to easily upload or import collateral files, e-sign documents, and engage in real-time chat for superfast collaboration.",
    icon: portal,
  },
  {
    id: 15,
    title: "Simplified Borrower Data Import",
    description:
      "Integrate with your CRM or core system via APIs to seamlessly import borrower information.",
    icon: imports,
  },
];

const factoringboxes = [
  {
    id: 1,
    title: "Auto-Funding",
    description:
      "Set up custom configurations to automatically approve or reject auto-funding against invoices.",
    icon: funding,
  },
  {
    id: 2,
    title: "Streamline Portfolio Management",
    description:
      "Simplify the entire process—from validating invoices and tracking verification letters to calculating advances, reserves, and fees.",
    icon: portfolio,
  },
  {
    id: 3,
    title: "Customize Debtor Emails",
    description:
      "Personalize invoice verification emails for each debtor and automatically dispatch them directly from within the application.",
    icon: email,
  },
  {
    id: 4,
    title: "Simplify Purchasing Decisions",
    description:
      "Effortlessly access new, ineligible, and funded invoices to conduct rapid risk assessments and expedite purchasing decisions.",
    icon: decesion,
  },
  {
    id: 5,
    title: "Streamlined Document Management",
    description:
      "Attach comments, multiple documents, and images to invoices, ensuring easy retrieval of files.",
    icon: effort,
  },
  {
    id: 6,
    title: "Robust Reporting",
    description:
      "Digitize and centralize all information, allowing users to view and customize reports and visualizations, with the option to print reports as needed",
    icon: robust,
  },
  {
    id: 7,
    title: "Real-Time Monitoring",
    description:
      "Monitor activity and critical data anytime, anywhere with real-time updates to minimize fraud and overall risk.",
    icon: timesaveblue,
  },
  {
    id: 8,
    title: "Sleek Client Portal",
    description:
      "An intuitive interface designed for sellers to quickly and easily manage invoices and payments.",
    icon: portal,
  },
  {
    id: 9,
    title: "Convenience of E-Signing",
    description:
      "Avoid the hassle of printing and mailing physical documents to sellers by using DocuSign for e-signatures.",
    icon: automated,
  },
  {
    id: 10,
    title: "Import Invoices from Accounting Software",
    description:
      "In addition to file uploads, with QuickBooks integration, sellers can directly export invoices without granting the factor any access to their accounting software.",
    icon: software,
  },
  {
    id: 11,
    title: "Secured & Dependable",
    description:
      "SOC2-certified application hosted on highly available, scalable, and fault tolerant Amazon Web Services cloud for top performance.",
    icon: borrow,
  },
];

const Lendingdata = [
  {
    id: 1,
    title: "Top-Notch Automation",
    description:
      "Automate lending workflows with AI and rules, cutting down manual work and significantly enhancing processing speed.",
    icon: topnatch,
  },
  {
    id: 2,
    title: "Enhanced Modularity Through Microservices",
    description:
      "Design custom solutions that meet unique business needs with an extensive microservices library.",
    icon: microservices,
  },
  {
    id: 3,
    title: "No-Code Customization",
    description:
      "Codeless template creation to implement business and policy decisions without engaging professional services.",
    icon: nocode,
  },
  {
    id: 4,
    title: "Easy Implementation",
    description:
      "Swift and efficient deployment with an intuitive design, robust support, and seamless integration capabilities.",
    icon: easy,
  },
  {
    id: 5,
    title: "360⁰ Visibility",
    description:
      "Gain access to actionable insights with customizable dashboard layouts and extensive data visualizations.",
    icon: three,
  },
  {
    id: 6,
    title: "Secure, Resilient, and Compliant",
    description:
      "SOC2-certified application hosted on highly available, scalable, and fault-tolerant Amazon Web Services cloud for top performance.",
    icon: secure,
  },
  {
    id: 7,
    title: "High-Productivity Design",
    description:
      "Liberate your analysts from mundane tasks, enabling them to oversee more portfolios or concentrate on higher-value tasks that demand their expertise.",
    icon: highprodcut,
  },
  {
    id: 8,
    title: "Effortless Integrations via APIs",
    description:
      "Seamlessly connect with third parties via APIs to foster innovation and improve customer experience.",
    icon: effortless,
  },
];

const CompanyData = [
  {
    id: 1,
    title: "Innovation",
    description:
      "We embrace creativity and continuously push boundaries to develop ground breaking solutions that anticipate and meet the evolving needs of lenders.",
    icon: innovation,
  },
  {
    id: 2,
    title: "Integrity",
    description:
      "Transparency, honesty, and accountability are the cornerstones of our interactions with lenders, their customers, and our partners.",
    icon: integrity,
  },
  {
    id: 3,
    title: "Agility",
    description:
      "We embrace change, adapt quickly to market dynamics, and proactively seek opportunities for growth by staying ahead of industry trends.",
    icon: agility,
  },
  {
    id: 4,
    title: "User-Centricity",
    description:
      "We are committed to understanding customer needs, delivering exceptional experiences, & providing tailored financial solutions that achieve their goals.",
    icon: centricity,
  },
  {
    id: 5,
    title: "Collaboration",
    description:
      "By partnering lenders and factors with our expertise, we bring together the collective intelligence and diverse perspectives making us a unique team.",
    icon: collab,
  },
  {
    id: 6,
    title: "Security & Privacy",
    description:
      "We prioritize the security & privacy by employing intelligent, cloud-based technologies and robust security measures to safeguard data.",
    icon: secureprivacy,
  },
  {
    id: 7,
    title: "Social Responsibility",
    description:
      "As a responsible corporate citizen, we actively contribute to the communities we serve and are committed to fostering sustainable practices.",
    icon: social,
  },
  {
    id: 8,
    title: "Empowerment",
    description:
      "Cync Software aspires to be a beacon of innovation, trust, and excellence in the fintech industry, empowering all to thrive in the next era of commercial lending.",
    icon: empower,
  },
];

const securityboxes = [
  {
    id: 1,
    title: "Robust Incident Management",
    description:
      "Swift identification, response, and resolution of security breaches to minimize impact and maintain operational integrity.",
    icon: rim,
  },
  {
    id: 2,
    title: "Streamlined Change Management",
    description: (
      <div style={{ fontSize: "16px" }}>
        Meticulous planning, assessment, and implementation of updates or
        modifications to ensure seamless integration, minimize disruptions, and
        maintain high reliability and performance standards for our clients.
      </div>
    ),
    icon: scm,
  },
  {
    id: 3,
    title: "Rigorous Vulnerability Management",
    description:
      "Proactive scanning and prompt patching to uphold robust protection against potential threats to ensure security, reliability, and availability of our systems and data.",
    icon: rvm,
  },
  {
    id: 4,
    title: "Proactive Threat Intelligence Monitoring",
    description:
      "Proactively monitoring and analyzing emerging cyber threats to anticipate and mitigate potential risks swiftly, ensuring heightened security posture and resilience against evolving threats.",
    icon: pim,
  },
  {
    id: 5,
    title: "Stringent Asset Management",
    description:
      "Meticulous tracking and protection of digital and cloud assets to implement additional security controls, such as Data Loss Prevention (DLP), to safeguard sensitive information from data leakages.",
    icon: sam,
  },
  {
    id: 6,
    title: "Strong Data Classification Measures",
    description:
      "Categorization of information based on sensitivity and enforcing security controls to ensure compliance with data protection regulations to reduce risks of unauthorized access and data breaches.",
    icon: sdcm,
  },
  {
    id: 7,
    title: "Strict Access Control",
    description:
      "Rigorous access control mechanisms to manage user permissions to ensure only authorized users can access sensitive data or functionalities.",
    icon: sac,
  },
  {
    id: 8,
    title: "Enhanced Web Security with WAF",
    description:
      "Safeguarding against OWASP’s top ten vulnerabilities by filtering web traffic to detect and block malicious requests while allowing legitimate traffic to pass through to web applications.",
    icon: esw,
  },
  {
    id: 9,
    title: "Comprehensive Penetration Testing Approach",
    description: (
      <div style={{ fontSize: "16px" }}>
        Exhaustive penetration testing by our internal security team after every
        major systemic change, supplemented by annual testing from an external
        security firm, to rigorously assess and enhance our system's security
        against vulnerabilities.
      </div>
    ),
    icon: cpt,
  },
  {
    id: 10,
    title: "Automated Capacity Planning",
    description:
      "Utilization of advanced tools for capacity planning to ensure on-demand scalability, reliability, and performance, supporting seamless operations and maximizing user satisfaction.",
    icon: acp,
  },
  {
    id: 11,
    title: "Secure Encryption for Data Protection",
    description:
      "Robust cryptographic security by employing TLS 1.2 encryption for data in transit and AES 256-bit encryption to protect data at rest, bolstered by encryption on all our servers to safeguard data and maintain secure communication.",
    icon: sedp,
  },
];

const syndicateboxes = [
  {
    id: 1,
    title: "Flexible Fee Management for Arrangers and Participants",
    description:
      "Enable both lead arrangers or agents and participants to charge standard fees—such as arrangement fees, commitment fees, administration fees, and prepayment fees—or define custom fees tailored to specific agreements.",
    icon: flexiblefee,
  },
  {
    id: 2,
    title: "Complex Interest Calculation ",
    description:
      "Account for each participant's terms and payment schedules when calculating interest, ensuring precise and accurate distributions.",
    icon: sofr,
  },
  {
    id: 3,
    title: "Streamlined Payment Processing",
    description:
      "Manage complex payment schedules and seamlessly coordinate with borrowers and participants to efficiently collect and distribute payments.",
    icon: paymentprocess,
  },
  {
    id: 4,
    title: "General Ledger for Every Participant",
    description:
      "Maintain a comprehensive general ledger for each participant in the syndicate, with an option to seamlessly integrate with their accounting software through APIs.",
    icon: ledger,
  },
  {
    id: 5,
    title: "Make Amendments to Loan Agreement",
    description:
      "Effortlessly adjust interest rates, extend maturities, modify covenants, alter repayment schedules, or update collateral requirements for syndicated loans.",
    icon: loanagreement,
  },
  {
    id: 6,
    title: "Automated Covenants & Tickler Management",
    description:
      "Ensure lender-borrower transparency as well as compliance via automated covenants and tickler management.",
    icon: automated,
  },
  {
    id: 7,
    title: "Comprehensive Dashboard and Reporting",
    description:
      "Gain actionable insights with our advanced analytics dashboard. Monitor settlement, loan transactions, and outstanding balances on demand with detailed reports.",
    icon: comprehensive,
  },
  {
    id: 8,
    title: "Avoid and Handle Defaults",
    description:
      "Monitor all due and overdue disbursements, send timely reminders, and set rules for fee collection in case of default.",
    icon: defaults,
  },
  {
    id: 9,
    title: "Automated, Real-Time Communication",
    description:
      "Fully automated, real-time transmission of updates on transactions, amendments, payment schedules, collateral valuations, and default notices to all lenders, investors, and borrowers.",
    icon: realtime,
  },
  {
    id: 10,
    title: "Effortless Document Management",
    description:
      "Securely store documents for tracking, compliance, and accountability. Restrict access based on roles and permissions. Add metadata to facilitate quick and easy retrieval.",
    icon: effort,
  },
  {
    id: 11,
    title: "Seamless Integration and Scalable Hosting",
    description:
      "Effortlessly connect with multiple third-party systems using APIs. Benefit from secure, scalable hosting on AWS private cloud, accommodating business growth while maintaining high performance.",
    icon: scalable,
  },
  {
    id: 12,
    title: "Customizable Communication Templates",
    description:
      "Accelerate communication with configurable templates for distributing loan and transaction details to all participants.",
    icon: template,
  },
  {
    id: 13,
    title: "Customizable Interface for Branding Consistency",
    description:
      "Customizable interface featuring the lead arranger's or agent's branding for enhanced consistency, professionalism, and recognition.",
    icon: easy,
  },
];

const sblboxes = [
  {
    id: 1,
    title: "Shield Against Price Surges",
    description:
      "Mitigate market fluctuation risks by enforcing price caps to automatically disqualify ineligible assets from the credit limit.",
    icon: sbl1,
  },
  {
    id: 2,
    title: "Actionable Insights Into Volatility",
    description:
      "Monitor volatility of pledged securities using Simple Moving Averages (SMA) across short-, intermediate-, and long-term timeframes to track price trends and identify potential risk factors. ",
    icon: sbl2,
  },
  {
    id: 3,
    title: "Broad Ticker Coverage",
    description:
      "Offer higher credit limits by lending against 150,000+ tickers and 20,000+ ETFs across 60+ global stock exchanges.",
    icon: sbl3,
  },
  {
    id: 4,
    title: "Client Portfolio Insights",
    description:
      "Analyze asset exposure across categories, spot volatile tickers, monitor outstanding balance, and quickly identify whether the client is in surplus or deficit.",
    icon: sbl4,
  },
  {
    id: 5,
    title: "Overall Portfolio Insights",
    description:
      "Promptly recognize the most volatile tickers and concentration risks across the portfolio, and detect clients nearing margin thresholds or in default.",
    icon: sbl5,
  },
  {
    id: 6,
    title: "High Configurability to Define LTVs",
    description:
      "Flexibility to configure LTV at both the asset category and individual ticker level.",
    icon: sbl6,
  },
  {
    id: 7,
    title: "Comprehensive Borrower Portal",
    description:
      "View-only access for borrowers to account summary, collateral details, and transaction history.",
    icon: sbl7,
  },
  {
    id: 8,
    title: "Permission-Based Access",
    description:
      "Restrict access to features and reports, ensuring only authorized users can perform specific actions.",
    icon: sbl8,
  },
  {
    id: 9,
    title: "Quick and Hassle-Free Integrations",
    description:
      "Utilize Cync’s Loan Origination and Servicing capabilities or integrate with third-party core banking solutions to streamline operations and enhance efficiency. ",
    icon: sbl9,
  },
];

export {
  Lendingdata,
  boxes,
  CompanyData,
  ablboxes,
  factoringboxes,
  spreadinboxes,
  securityboxes,
  syndicateboxes,
  sblboxes,
};
