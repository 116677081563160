// ThankYouPage.js
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const FactoringThankYouPage = () => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(5); // State for countdown timer

   useEffect(() => {
      if (window.gtag) {
        window.gtag("event", "page_view", {
          page_path: "/cync-factoring/thank-you",
        });
      }
    }, []);

  useEffect(() => {
    // Set interval for countdown
    const countdownTimer = setInterval(() => {
      setCountdown((prev) => prev - 1); // Decrease countdown by 1
    }, 1000);

    // Redirect after 5 seconds
    const timer = setTimeout(() => {
      navigate("/cync-factoring");
    }, 5000);

    return () => {
      clearTimeout(timer); // Cleanup redirection timer
      clearInterval(countdownTimer); // Cleanup countdown timer
    };
  }, [navigate]);

  return (
    <div style={styles.pageContainer}>
        <Helmet>
        <title>Thankyou - CyncSoftware</title>
        <script src="https://cyncsoftware.com/analytics.js"></script>
      </Helmet>
      <Container>
        <div className="space"></div>
        <div className="space"></div>
        <div className="space"></div>
        <div style={styles.contentContainer}>
          <h1>Thank You!</h1>
          <p>Thanks for submitting your details. One of our team members will contact you shortly.</p>
          <p style={styles.redirectMessage}>
          You will be redirected to your previous page in <strong>{countdown} seconds</strong>...
          </p>
          <p style={{fontSize:'13px'}}><a href="/cync-factoring">Click here</a> if the page doesn't redirect automatically.</p>
        </div>
        <div className="space"></div>
        <div className="space"></div>
        <div className="space"></div>
      </Container>
    </div>
  );
};
const styles = {
  pageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "80vh",
    textAlign: "center",
    backgroundColor: "#f9f9f9",
    padding: "20px", 
  },
  contentContainer: {
    maxWidth: "600px",
    margin: "0 auto",
  },
  redirectMessage: {
    marginTop: "20px",
    fontSize: "18px",
    color: "#666",
  },
};

export default FactoringThankYouPage;
