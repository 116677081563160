import React, { useState } from "react";
import { Container, Tab, Tabs, Row, Col, Modal } from "react-bootstrap";
import "../../assets/css/Careers.css";
import Button from "../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import HrLines from "../designs/HrLines";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";

const Careers = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleConfirmRedirect = () => {
    handleCloseModal();
    window.open(
      "https://www.linkedin.com/company/cyncsoftware/jobs/",
      "_blank" // This attribute opens the link in a new tab
    );
  };

  const [key, setKey] = useState("tab1");

  const tabContents = [
    {
      eventKey: "tab1",
      title: "Core Values",
      content: (
        <div>
          <h1>Core Values</h1>
          <br />
          <p className="contentpara">
            At the heart of Cync Software lies a set of core values that guide
            every decision we make and every action we take. Integrity,
            Innovation, Collaboration, Passion, and Excellence are not just
            words; they are the pillars upon which our culture is built. These
            values are more than just a statement on paper; they are ingrained
            in our daily operations, driving us to achieve greatness
            individually and as a team.
          </p>
        </div>
      ),
      image: "/images/careers-1.png",
    },
    {
      eventKey: "tab2",
      title: "Commitment to Innovation",
      content: (
        <div>
          <h1>Commitment to Innovation</h1>
          <br />
          <p className="contentpara">
            We don’t just embrace change; we drive it. Our commitment to
            innovation extends beyond our products and services to the very
            fabric of our company culture. We encourage a mindset of continuous
            improvement, where every team member is empowered to contribute
            ideas, experiment, and push the boundaries of what’s possible.
          </p>
        </div>
      ),
      image: "/images/careers-2.png",
    },
    {
      eventKey: "tab3",
      title: "Inclusivity",
      content: (
        <div>
          <h1>Inclusivity</h1>
          <br />
          <p className="contentpara">
            Diversity is not just a buzzword for us; it’s a cornerstone of our
            culture. We celebrate differences and recognize that a diverse team
            brings a wealth of perspectives, ideas, and experiences. Inclusivity
            is not just a checkbox for us; it’s a commitment to creating an
            environment where everyone feels valued, heard, and empowered to
            contribute their best.
          </p>
        </div>
      ),
      image: "/images/careers-3.png",
    },
    {
      eventKey: "tab4",
      title: "Investing in Employee Growth",
      content: (
        <div>
          <h1>Investing in Employee Growth</h1>
          <br />
          <p className="contentpara">
            We understand that our success is intrinsically tied to the growth
            and development of our team members. Our commitment to continuous
            learning and professional development goes beyond mere lip service.
            From mentorship programs to ongoing training opportunities, we
            invest in our employees, empowering them to expand their skills and
            reach their full potential.
          </p>
        </div>
      ),
      image: "/images/careers-4.png",
    },
    {
      eventKey: "tab5",
      title: "A Magnet for Talent",
      content: (
        <div>
          <h1>A Magnet for Talent</h1>
          <br />
          <p className="contentpara">
            At Cync Software, we understand that the best and brightest want to
            be part of a workplace that values their contributions, nurtures
            their growth, and fosters a culture of excellence. If you’re ready
            to be part of a company where culture drives success and talent
            thrives, explore our current opportunities and discover how you can
            contribute to the next chapter of Cync success story.
          </p>
        </div>
      ),
      image: "/images/careers-5.png",
    },
    {
      eventKey: "tab6",
      title: "Work-Life Balance",
      content: (
        <div>
          <h1>Work-Life Balance</h1>
          <br />
          <p className="contentpara">
            We believe in the importance of a healthy work-life balance. At Cync
            Software, we prioritize the well-being of our employees, recognizing
            that a happy and fulfilled team is a more productive and innovative
            one. Flexible work arrangements, wellness programs, and a supportive
            environment are just a few ways we ensure our team can thrive both
            personally and professionally.
          </p>
        </div>
      ),
      image: "/images/careers-6.png",
    },
  ];

  return (
    <div>
      <Helmet>
        <title>Careers and Culture - Cync Software®</title>
        <meta
          name="description"
          content="Our people are our greatest asset, fostering a vibrant, inclusive culture that attracts top fintech talent."
        />
      </Helmet>

      <motion.div
        className="Productright"
        initial={{ opacity: 0, x: 50 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
        viewport={{ once: true, amount: 0.3 }}
      >
        <section className="careers-sections">
          <Container className="h-100">
            <Row className="h-100 align-items-center">
              <Col lg={9}>
                <div className="content">
                  <motion.div
                    className="Productleft"
                    initial={{ opacity: 0, x: -50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.8, ease: "easeOut" }}
                    viewport={{ once: true, amount: 0.7 }}
                  >
                    <h1>Careers & Culture</h1>
                  </motion.div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </motion.div>

      <div className="space"></div>

      <section className="customs-tabs-container">
        <h1 style={{ textAlign: "center" }}>
          Where Culture & Inclusivity Drive Innovation
        </h1>
        <HrLines />
        <div className="space"></div>
        <Tabs
          style={{ marginBottom: "0px !important" }}
          id="custom-tabs"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3 customs-tabs"
        >
          {tabContents.map((tab, index) => (
            <Tab
              key={index}
              eventKey={tab.eventKey}
              title={tab.title}
              className="customs-tab"
            >
              <Row className="customs-tab-content">
                <Col md={7} className="left-content">
                  {tab.content}
                </Col>
                <Col md={5} className="right-image">
                  <img src={tab.image} alt={`Image for ${tab.title}`} />
                </Col>
              </Row>
            </Tab>
          ))}
        </Tabs>
      </section>

      <div className="space"></div>

      <section>
        <Container>
          <Row>
            <Col className="text-center">
              <h1 style={{ textAlign: "center" }}>
                Ready to Make a Difference?
              </h1>
              <HrLines />
              <br />
              <p style={{ fontSize: "22px", textAlign: "left" }}>
                We believe that our people are our greatest asset. Our
                commitment to fostering a vibrant and inclusive company culture
                sets the foundation for our success and positions us as a magnet
                for top-tier talent within the “Fintech” (financial technology)
                industry.
              </p>
              <Button size="small" color="primary" onClick={handleShowModal}>
                {" "}
                <FontAwesomeIcon icon={faLinkedin} /> View Positions
              </Button>
              <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                  <Modal.Title>Redirect Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Are you sure you want to leave this site and go to an external
                  website?
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleCloseModal}>
                    Cancel
                  </Button>
                  <Button variant="primary" onClick={handleConfirmRedirect}>
                    OK
                  </Button>
                </Modal.Footer>
              </Modal>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="space"></div>
    </div>
  );
};

export default Careers;
